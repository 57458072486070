/* -Common nav patterns
-----------------------------------------------------------------------------*/
.nav-bar,
.nav-list {
  &,
  & ul {
    list-style: none;
    margin: 0;
  }
  li {
    display: inline-block;
  }
  a {
    display: block;
  }
}

.nav-list {
  a {
    text-decoration: none;
  }
}
