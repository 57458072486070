/* -List of estate objects
-----------------------------------------------------------------------------*/
$estate-list-item-width: 800px;
$estate-list-gutter: 5px;

@mixin estate-list-columns($col-count) {
  max-width: ($estate-list-item-width * $col-count) +
    ($estate-list-gutter * ($col-count - 1));
}

.estate-list-intro {
  text-align: center;
}

.estate-list-wrap {
  @include estate-list-columns(2);
  margin-left: auto;
  margin-right: auto;
}
.estate-list {
  @include grid-wrap;
  @include grid-pull-bottom;
}
.estate-list-item {
  @include grid-item(6);
  margin-bottom: 50px;
}
@include media-min(em(2300px)) {
  .estate-list-wrap {
    @include estate-list-columns(3);
  }
  .estate-list-item {
    @include grid-item-size(4);
  }
}
@include media-max(em(950px)) {
  .estate-list-wrap {
    @include estate-list-columns(1);
  }
  .estate-list-item {
    @include grid-item-size(12);
  }
}

.estate-list-item-inner {
  position: relative;
  // Hide banner from overflowing
  overflow: hidden;
}
.estate-list-item-image {
  vertical-align: middle;
}
.estate-list-item-image-link {
  display: block;
}
.estate-list-item-title {
  margin-top: 1em;

  p {
    margin: 5px 0;
  }
  a {
    color: inherit;

    &:hover,
    &:focus {
      text-decoration: underline;
    }
  }
}
.estate-list-item-title-primary {
  margin: 0;
  color: inherit;
}

@include media-max(em(450px)) {
  .estate-list-item-inner {
    &:after {
      height: 200px;
    }
  }
  .estate-list-item-title {
    padding: 10px 10px 15px;
  }
}
