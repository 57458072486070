/* -Media block - a background image or video with optional content on
----------------------------------------------------------------------------*/
// Must have a wrapping column flex container for IE11
// https://github.com/philipwalton/flexbugs#flexbug-3
.media-container {
  display: flex;
  flex-direction: column;
}

.media {
  position: relative;
  padding-top: 20px;
  padding-bottom: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  overflow: hidden;

  height: 56vw;

  @include media-min(rem(600px)) {
    max-height: 70vh;
  }
}

.media-bg {
  // Ensure text is readable if image or video fails to load
  background-color: #808080;

  &,
  picture {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
  }
  img,
  video {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

$media-content-padding: 90px;
.media-content {
  position: relative;
  width: 100%;
  padding-top: rem($media-content-padding);
  padding-bottom: rem($media-content-padding);
  color: #fff;
  text-align: center;
  z-index: 10;

  h1,
  h2,
  h3 {
    @extend %h2;
  }
  h1,
  h2,
  h3,
  p {
    color: inherit;
    text-shadow: 0 0 20px rgba(0, 0, 0, 0.7);

    @include media-max(rem(600px)) {
      font-size: rem(20px);
    }
  }
}
