/* -Tab-like radio button filter
-----------------------------------------------------------------------------*/
.radio-filter {
  text-align: center;
}

.radio-filter-item {
  margin: 0 10px 10px;
  @include font-size(14px);
  text-transform: uppercase;

  input:focus + .filter-box {
    @include filter-box-focus;
  }
  input:focus + .filter-box--selected {
    @include filter-box--selected-focus;
  }

  @include media-max(em(500px)) {
    margin: 0 5px 10px;
    @include font-size(13px);
  }
}
