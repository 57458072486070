/* -Wrap elements
-----------------------------------------------------------------------------*/
@mixin page-margin {
  padding-left: $page-margin;
  padding-right: $page-margin;

  @include media-max($breakpoint-page-margin-small) {
    padding-left: $page-margin * 0.5;
    padding-right: $page-margin * 0.5;
  }
}
%page-margin {
  @include page-margin;
}

// ---------- Padded wraps, default ---------- //
@mixin wrap-base {
  @include page-margin;

  margin-left: auto;
  margin-right: auto;
}
%wrap-base {
  @include wrap-base;
}

// Widths
@mixin wrap-width {
  max-width: $wrap-width + ($page-margin * 2);
}
@mixin wrap-width-thin {
  max-width: $wrap-width-thin + ($page-margin * 2);
}
@mixin wrap-width-extra-thin {
  max-width: $wrap-width-extra-thin + ($page-margin * 2);
}
@mixin wrap-width-medium {
  max-width: $wrap-width-medium + ($page-margin * 2);
}
@mixin wrap-width-wide {
  max-width: $wrap-width-wide + ($page-margin * 2);
}
%wrap-width {
  @include wrap-width;
}
%wrap-width-thin {
  @include wrap-width-thin;
}
%wrap-width-extra-thin {
  @include wrap-width-extra-thin;
}
%wrap-width-medium {
  @include wrap-width-medium;
}
%wrap-width-wide {
  @include wrap-width-wide;
}

// Wrappers
@mixin wrap {
  @include wrap-base;
  @include wrap-width;
}
@mixin wrap-thin {
  @include wrap-base;
  @include wrap-width-thin;
}
@mixin wrap-extra-thin {
  @include wrap-base;
  @include wrap-width-extra-thin;
}
@mixin wrap-medium {
  @include wrap-base;
  @include wrap-width-medium;
}
@mixin wrap-wide {
  @include wrap-base;
  @include wrap-width-wide;
}
%wrap {
  @extend %wrap-base;
  @extend %wrap-width;
}
%wrap-thin {
  @extend %wrap-base;
  @extend %wrap-width-thin;
}
%wrap-extra-thin {
  @extend %wrap-base;
  @extend %wrap-width-extra-thin;
}
%wrap-medium {
  @extend %wrap-base;
  @extend %wrap-width-medium;
}
%wrap-wide {
  @extend %wrap-base;
  @extend %wrap-width-wide;
}
.wrap {
  @extend %wrap;
}
.wrap--thin {
  @extend %wrap-thin;
}
.wrap--extra-thin {
  @extend %wrap-extra-thin;
}
.wrap--medium {
  @extend %wrap-medium;
}
.wrap--wide {
  @extend %wrap-wide;
}

// To get page margins without max-width
.wrap--full {
  max-width: none;
}

// ---------- Full width wraps ---------- //
@mixin edge-wrap-base {
  margin-left: auto;
  margin-right: auto;
}
%edge-wrap-base {
  @include edge-wrap-base;
}

// Widths
@mixin edge-wrap-width {
  max-width: $wrap-width;
}
@mixin edge-wrap-width-thin {
  max-width: $wrap-width-thin;
}
@mixin edge-wrap-width-extra-thin {
  max-width: $wrap-width-extra-thin;
}
@mixin edge-wrap-width-wide {
  max-width: $wrap-width-wide;
}
%edge-wrap-width {
  @include edge-wrap-width;
}
%edge-wrap-width-thin {
  @include edge-wrap-width-thin;
}
%edge-wrap-width-extra-thin {
  @include edge-wrap-width-extra-thin;
}
%edge-wrap-width-wide {
  @include edge-wrap-width-wide;
}

// Wrappers
@mixin edge-wrap {
  @include edge-wrap-base;
  @include edge-wrap-width;
}
@mixin edge-wrap-thin {
  @include edge-wrap-base;
  @include edge-wrap-width-thin;
}
@mixin edge-wrap-extra-thin {
  @include edge-wrap-base;
  @include edge-wrap-width-extra-thin;
}
@mixin edge-wrap-wide {
  @include edge-wrap-base;
  @include edge-wrap-width-wide;
}
%edge-wrap {
  @extend %edge-wrap-base;
  max-width: $wrap-width;
}
%edge-wrap-thin {
  @extend %edge-wrap-base;
  max-width: $wrap-width-thin;
}
%edge-wrap-extra-thin {
  @extend %edge-wrap-base;
  max-width: $wrap-width-extra-thin;
}
%edge-wrap-wide {
  @extend %edge-wrap-base;
  max-width: $wrap-width-wide;
}
.edge-wrap {
  @extend %edge-wrap;
}
.edge-wrap--thin {
  @extend %edge-wrap-thin;
}
.edge-wrap--extra-thin {
  @extend %edge-wrap-extra-thin;
}
.edge-wrap--wide {
  @extend %edge-wrap-wide;
}
