// Inspired by medium.com's approach. Define all z-index values here to get an
// easy overview of all the layers. Exceptions can be made if it's just local,
// like adding z-index: 1 just to place it above siblings.

// Z-Index Scale (private vars)
// --------------------------------------------------
$z-index-1: 100;
$z-index-2: 200;
$z-index-3: 300;
$z-index-4: 400;
$z-index-5: 500;
$z-index-6: 600;
$z-index-7: 700;
$z-index-8: 800;
$z-index-9: 900;
$z-index-10: 1000;

// Z-Index Applications
// --------------------------------------------------
$z-component-grid: $z-index-2;

$z-overlay-link: $z-index-4;

$z-skiplink: $z-index-6;
$z-fixed-nav: $z-index-6;

$z-modal: $z-index-7;

$z-online-status: $z-index-8;
$z-notifications: $z-index-8;

$z-comm-info: $z-index-9;
