/* -Tabs and panels
-----------------------------------------------------------------------------*/
/* Not really styled as tabs though */

@use "sass:math";

.tab-list {
  @include grid-wrap;
  @include grid-wrap-size(20px);
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  margin-bottom: 0;
}

.tab {
  @include grid-item(12);
  @include grid-item-gutter-size(20px);

  display: flex;

  @for $i from 2 through 6 {
    .tab-list--count-#{$i} & {
      width: math.div(100%, $i);
    }
  }
  a {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    text-decoration: none;
    color: inherit;
    background-color: #fff;
  }
  a:focus {
    outline: 0;
    border-color: $color-action;
  }
}
.tab-item {
  display: block;
  padding: 15px 20px;

  h2,
  h3,
  h4,
  h5,
  h6,
  p {
    margin: 0;
  }
}

.tab-panel--hidden {
  display: none;
}

/*---------- Form tabs ----------*/
.form-tabs-intro {
  margin-bottom: 30px;
  text-align: center;
}
.form-tab-list {
  margin: 0 auto 50px;
  max-width: $wrap-width + 8em;
}
@include media-max(em(900px)) {
  .form-tabs .tab-item {
    text-align: center;

    p {
      display: none;
    }
  }
}
@include media-max(em(700px)) {
  .form-tabs .tab-item {
    h3 {
      font-size: 1em;
    }
  }
}
@include media-max(em(550px)) {
  .form-tabs .tab {
    width: 100%;
    margin-top: 25px;
  }
}
