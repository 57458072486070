/* -Tables
-----------------------------------------------------------------------------*/
table,
tr,
th,
td {
  border: 0;
}
table {
  border-spacing: 0;
  border-collapse: collapse;
  font-size: inherit;
}
th {
  font-weight: 700;
  vertical-align: bottom;
}
td,
td img {
  font-weight: normal;
  vertical-align: top;
}
th,
td {
  background: #fff;
  padding: 10px 20px;
  text-align: left;
  white-space: nowrap;

  tbody tr:nth-child(odd) & {
    background: #f6f6f6;
  }
  @include media-max(600px) {
    padding: 5px 10px;
  }
}

.table-wrap {
  overflow-x: auto;
}
