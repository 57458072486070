/* -Estates excerpt images
-----------------------------------------------------------------------------*/
$estates-excerpt-image-width: 900px;

.estates-excerpt {
  text-align: center;
}
.estates-excerpt-images {
  display: flex;
  max-width: $estates-excerpt-image-width * 2;
  margin-bottom: 20px;
}
.estates-excerpt-image {
  width: 50%;
}

@include media-max(1600px) {
  .estates-excerpt-images {
    padding-left: 0;
    padding-right: 0;
  }
}
