/* -Hover tooltips
-----------------------------------------------------------------------------*/
/* Defaults to top position, add extra classes for bottom, left or right.
The double border-style fixes jagged edges in Firefox. */
.tooltip {
  position: relative;

  &:before,
  &:after {
    display: block;
    position: absolute;
    bottom: 100%;
    left: 50%;
    visibility: hidden;
    z-index: 1;
    opacity: 0;
    transition: opacity 0.1s ease 0.1s, visibility 0s ease 0.2s;
  }

  /* Arrow */
  &:before {
    content: '';
    margin: 0 0 -3px -5px;
    border-width: 5px;
    border-style: double;
    border-color: transparent;
    border-top-style: solid;
    border-top-color: #222;
  }

  /* Text */
  &:after {
    content: attr(data-tooltip);
    margin-bottom: 6px;
    padding: 2px 0.5em;
    background: #222;
    color: #fff;
    white-space: nowrap;
    @include font-size(12px);
    line-height: $base-line-height;
    font-weight: normal;
    text-align: center;
    transform: translateX(-50%);

    .no-csstransforms & {
      margin-left: -50%;
    }
  }
  &:hover,
  &:focus,
  &--show {
    &:before,
    &:after {
      visibility: visible;
      opacity: 1;
      transition-delay: 0.1s 0s;
    }
  }
  .touched &:focus {
    &:before,
    &:after {
      visibility: hidden;
      opacity: 0;
    }
  }
}
.tooltip--bottom {
  &:before,
  &:after {
    bottom: auto;
    top: 100%;
  }
  &:before {
    margin: -3px 0 0 -5px;
    border-top-style: double;
    border-top-color: transparent;
    border-bottom-style: solid;
    border-bottom-color: #222;
  }
  &:after {
    margin-bottom: 0;
    margin-top: 6px;
  }
}
.tooltip--left {
  &:before,
  &:after {
    bottom: auto;
    left: auto;
    top: 50%;
    right: 100%;
  }
  &:before {
    margin: -5px -3px 0 0;
    border-top-style: double;
    border-top-color: transparent;
    border-left-style: solid;
    border-left-color: #222;
  }
  &:after {
    margin-bottom: 0;
    margin-right: 6px;
    transform: translateY(-50%);

    .no-csstransforms & {
      margin-left: 0;
      margin-top: -0.75em;
    }
  }
}
.tooltip--right {
  &:before,
  &:after {
    bottom: auto;
    left: 100%;
    top: 50%;
  }
  &:before {
    margin: -5px 0 0 -3px;
    border-top-style: double;
    border-top-color: transparent;
    border-right-style: solid;
    border-right-color: #222;
  }
  &:after {
    margin-bottom: 0;
    margin-left: 6px;
    transform: translateY(-50%);

    .no-csstransforms & {
      margin-left: 0;
      margin-top: -0.75em;
    }
  }
}
