/* -Buttons
-----------------------------------------------------------------------------*/

/*---------- Mixins ----------*/
@mixin button {
  display: inline-block;
  height: $form-control-height;
  line-height: $form-control-height - em(5px);
  padding: 0 15px;
  border: 2px solid;
  border-radius: 3px;
  @include font-size(16px);
  font-weight: 400;
  text-decoration: none;
  text-align: center;
  vertical-align: middle;
  white-space: nowrap;
  text-shadow: none;
  box-shadow: none;

  &[disabled],
  &.disabled {
    opacity: 0.5;
    cursor: default;
  }
  > span {
    vertical-align: middle;
  }

  &:focus {
    outline: 2px dotted $color-body-foreground;
  }
}

@mixin button-primary-colors {
  background-color: $color-action;
  color: $color-action-foreground;
  border-color: $color-action;
}
@mixin button-primary {
  @include button;
  @include button-primary-colors;

  &:hover,
  &:focus {
    background-color: lighten($color-action, 10%);
    border-color: lighten($color-action, 10%);
  }
  &[disabled],
  &.disabled {
    background-color: desaturate($color-action, 20%);
    color: $color-action-foreground;
    border-color: desaturate($color-action, 20%);
  }
}

@mixin button-inverted-colors {
  background-color: #fff;
  color: $color-action;
  border-color: $color-action;
}
@mixin button-inverted {
  @include button;
  @include button-inverted-colors;

  &:hover,
  &:focus {
    background-color: #fff;
    color: darken($color-action, 10%);
    border-color: darken($color-action, 10%);
  }
  &[disabled],
  &.disabled {
    @include button-inverted-colors;
  }
}

@mixin button-secondary-colors {
  background-color: $color-gray-light;
  color: $color-body-foreground;
  border-color: $color-gray-light;
}
@mixin button-secondary {
  @include button;
  @include button-secondary-colors;

  &:hover,
  &:focus {
    background-color: darken($color-gray-light, 10%);
    border-color: darken($color-gray-light, 10%);
  }
  &[disabled],
  &.disabled {
    @include button-secondary-colors;
  }
}

@mixin button-confirm-colors {
  background-color: $color-success;
  color: #fff;
  border-color: $color-success;
}
@mixin button-confirm {
  @include button;
  @include button-confirm-colors;

  &:hover,
  &:focus {
    background-color: darken($color-success, 10%);
    border-color: darken($color-success, 10%);
  }
  &[disabled],
  &.disabled {
    @include button-confirm-colors;
  }
}

/*---------- Standard button classes ----------*/
.btn-base,
a.btn-base {
  @include button;
}

.btn,
a.btn,
button,
input[type='submit'],
input[type='button'],
input[type='reset'] {
  @include button-primary;

  // Shouldn't really, but the outline wraps the tooltip and looks really wonky
  &[data-tooltip]:focus {
    outline: 0;
  }
}

.btn-base,
a.btn-base,
.btn,
a.btn,
button,
input[type='submit'],
input[type='button'],
input[type='reset'] {
  .spinner {
    margin-top: -1px;
    margin-left: 0.5em;
  }
  .icon,
  .text {
    vertical-align: middle;
  }
  /* A single child with vertical-align middle isn't middle aligned, oddly enough */
  .text:first-child:last-child {
    vertical-align: baseline;
  }
  .icon {
    position: relative;

    &:first-child {
      left: -4px;
      margin-right: 4px;
    }
    &:last-child {
      right: -4px;
      margin-left: 4px;
    }
    &:first-child:last-child {
      left: auto;
      right: auto;
      margin-left: 0;
      margin-right: 0;
    }
  }
}

.btn-primary-colors,
a.btn-primary-colors {
  @include button-primary-colors;
}

.btn-inverted,
a.btn-inverted {
  @include button-inverted;
}
.btn-inverted-colors,
a.btn-inverted-colors {
  @include button-inverted-colors;
}

.btn-secondary,
a.btn-secondary {
  @include button-secondary;
}

.btn-secondary-colors,
a.btn-secondary-colors {
  @include button-secondary-colors;
}

.btn-confirm,
a.btn-confirm {
  @include button-confirm;
}

.btn-confirm-colors,
a.btn-confirm-colors {
  @include button-confirm-colors;
}

/*---------- Specialized styles ----------*/

/* A slightly smaller button */
@mixin btn-small {
  height: auto;
  line-height: 1.25;
  padding: 2px 10px;
  @include font-size(12px);
}
.btn-small {
  @include btn-small;
}

/* Semantically used buttons that shouldn't look like buttons */
@mixin btn-neutral {
  height: auto;
  padding: 0;
  border: 0;
  border-radius: 0;
  font-size: inherit;
  line-height: inherit;

  &:not([disabled]) {
    &,
    &:hover,
    &:focus {
      background: transparent;
      color: $color-body-foreground;
    }
  }
}
.btn-neutral {
  @include btn-neutral;
}

/* Plain text buttons */
@mixin btn-plain {
  height: auto;
  padding: 0;
  border: 0;
  border-radius: 0;
  line-height: $base-line-height;
  background: transparent;
  color: $color-action;
  text-decoration: underline;

  &:not([disabled]) {
    &:hover,
    &:focus {
      background: transparent;
      color: $color-action-active;
      text-decoration: none;
    }
    &:focus {
      outline: 1px dotted;
    }
  }
}
.btn-plain,
input.btn-plain {
  @include btn-plain;
}

/* 'Dangerous' buttons (delete, reset etc.) */
@mixin btn-danger {
  @include btn-plain;

  color: $color-error;

  &:not([disabled]) {
    &:hover,
    &:focus {
      background: $color-error;
      color: #fff;
    }
  }
}
.btn-danger,
input.btn-danger {
  @include btn-danger;
}

/* Close button, with a cross icon */
.btn-close {
  width: 40px;
  height: 40px;
  padding: 0;
  border: 0;
  background: transparent;
  color: rgba(0, 0, 0, 0.5);
  line-height: 40px;
  font-size: 26px;
  font-weight: $font-weight-bold;
  text-align: center;

  &:hover,
  &:focus {
    background: transparent;
    color: rgba(0, 0, 0, 0.75);
  }
}

@mixin btn-chevron {
  @include button-primary();
  position: relative;
  padding-right: 28px;

  &:after {
    content: '';
    position: absolute;
    right: 12px;
    top: 50%;
    transform: rotate(45deg) translateY(-50%);
    transform-origin: 50% 0;
    width: 15px;
    height: 15px;
    border-style: solid;
    border-width: 2px 2px 0 0;
  }
}
.btn-chevron,
input.btn-chevron,
a.btn-chevron {
  @include btn-chevron();
}

@mixin btn-chevron-white {
  @include btn-chevron();

  background-color: #fff;
  color: $color-dark;
  border: none;

  &:hover,
  &:focus {
    background-color: darken(#fff, 10%);
  }
}
.btn-chevron-white,
input.btn-chevron-white {
  @include btn-chevron-white();
}
