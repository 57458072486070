/* -A box used in filtering/tab components
-----------------------------------------------------------------------------*/
@mixin filter-box-focus {
  border-color: #999;
}
@mixin filter-box--selected-focus {
  border-color: darken($color-action, 15%);

  &:before {
    border-top-color: darken($color-action, 15%);
  }
}

.filter-box {
  position: relative;
  display: inline-block;
  padding: 3px 15px;
  border: 2px solid #ddd;

  &:hover {
    border-color: #999;
  }
}
.filter-box--selected {
  border-color: $color-action;

  &:before,
  &:after {
    content: '';
    display: block;
    position: absolute;
    top: 100%;
    left: 50%;
    width: 0;
    height: 0;
    border-style: solid;
    border-color: transparent;
  }
  &:before {
    margin-left: -18px;
    border-width: 12px 18px;
    border-top-color: $color-action;
  }
  &:after {
    margin-top: -1px;
    margin-left: -16px;
    border-width: 11px 16px;
    border-top-color: #fff;
  }
  &:hover {
    border-color: $color-action;
  }
  &:hover:before {
    border-top-color: $color-action;
  }
  &.filter-box--large {
    &:before {
      margin-left: -30px;
      border-width: 20px 30px;
    }
    &:after {
      margin-left: -28px;
      border-width: 19px 28px;
    }
  }
}
