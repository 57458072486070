/* -'Images' block
-----------------------------------------------------------------------------*/
$images-block-gutter: rem($text-spacing);

.images-block-wrap {
  @include section-margin(0.5);
}
.images-block {
  &:first-child {
    margin-top: 0;
  }
}

.images-block--small,
.images-block--medium,
.images-block--large {
  .images-block-item {
    @include grid-spacing-bottom-size($images-block-gutter);

    margin-right: auto;
    margin-left: auto;

    &:last-child {
      margin-bottom: 0;
    }
  }
}

.images-block--small .images-block-item {
  max-width: 200px;
}
.images-block--medium .images-block-item {
  max-width: 600px;
}
.images-block--large .images-block-item {
  max-width: 1000px;
}

.images-block-item-caption {
  p {
    margin-bottom: 0;
  }
  .images-block--small &,
  .images-block--medium &,
  .images-block--large &,
  .images-block--cols-2 &,
  .images-block--cols-3 &,
  .images-block--cols-4 & {
    margin-top: 5px;
    font-size: rem(14px);
  }
}

.images-block-item-image {
  a {
    transition: opacity 0.15s ease;

    &:hover,
    &:focus {
      opacity: 0.75;
    }
  }
}

/*-------------------- Columns layout --------------------*/
.images-block--cols-2,
.images-block--cols-3,
.images-block--cols-4 {
  @include grid-wrap();
  @include grid-wrap-size($images-block-gutter);
  @include grid-pull-bottom-size($images-block-gutter);

  .images-block-item {
    @include grid-item(12);
    @include grid-item-gutter-size($images-block-gutter);
    @include grid-spacing-bottom-size($images-block-gutter);
  }
}

@include media-min(rem(400px)) {
  .images-block--cols-2,
  .images-block--cols-4 {
    .images-block-item {
      @include grid-item-size(6);
    }
  }
}

@include media-min(rem(500px)) {
  .images-block--cols-3 {
    .images-block-item {
      @include grid-item-size(3);
    }
  }
}
@include media-min(rem(700px)) {
  .images-block--cols-4 {
    .images-block-item {
      @include grid-item-size(3);
    }
  }
}
/*-------------------- Text-to-the-side layout --------------------*/
.images-block--side-text {
  .images-block-item {
    @include grid-spacing-top-size($images-block-gutter);
  }
  .images-block-item-caption {
    margin-top: 10px;
  }

  @include media-min(rem(550px)) {
    .images-block-item {
      @include grid-wrap();
      @include grid-wrap-size($images-block-gutter);

      &:nth-child(even) {
        flex-direction: row-reverse;
      }
    }
    .images-block-item-image,
    .images-block-item-caption {
      @include grid-item(6);
      @include grid-item-gutter-size($images-block-gutter);
    }
    .images-block-item-caption {
      margin-top: 0;
    }
  }
}
