// Color definitions
$color-brand-blue: #264272;
$color-brand-blue-tint: #245caa;
$color-brand-blue-shade: #181a2f;
$color-brand-blue-soft: #535964;
$color-blue: #00506a;
$color-blue-gray: #5e758e;
$color-blue-light: #e7f2f4;
$color-gray-light: #f4f4f4;
$color-brown: #86422c;
$color-gold: #c0b285;
$color-gold-tint: desaturate(lighten($color-gold, 10%), 10%);
$color-cream: #f5f0eb;
$color-dark: #333;

// Lightest possible foreground gray that passes WCAG contrast requirement AA for
// small and large text, as well as AAA for larger text on a white background.
$color-lightest-foreground-on-white: #757575;

// Semantic assignments. When possible and logical, use colors through more
// meaningful, "use case" names.
$color-body-foreground: $color-brand-blue-soft;
$color-body-background: #fff;

$color-action: $color-blue;
$color-action-foreground: #fff;
$color-action-active: $color-brand-blue-shade;

$color-white-separator: #e8e8e8;

$color-form-field: $color-gray-light;
$color-form-field-border: $color-gold-tint;
$color-form-field-border-hover: #aaa;
$color-form-field-border-focus: desaturate($color-action, 20%);

$color-site-header-border: $color-brand-blue;
$color-site-nav-border: $color-brand-blue-soft;

$color-selection: desaturate(lighten($color-brand-blue, 30%), 30%);

$color-quote-foreground: $color-lightest-foreground-on-white;

// Notification colors
$color-notification-error: #ed404b;
$color-notification-error-foreground: #fff;
$color-notification-success: #49ad5a;
$color-notification-success-foreground: #fff;
$color-notification-info: #3b9bc9;
$color-notification-info-foreground: #fff;
$color-notification-warning: #fce473;
$color-notification-warning-foreground: #78591c;

// Message colors
$color-message-info-background: #d5e9f9;
$color-message-info-foreground: #06608c;
$color-message-warning-background: #fbfbca;
$color-message-warning-foreground: #886e08;
$color-message-error-background: #f1d6d6;
$color-message-error-foreground: #ab0e0b;
$color-message-success-background: #daf3d0;
$color-message-success-foreground: #0a7b0c;

// Form colors
$color-error: #f2414d;
$color-error-background: #fae8e6;
$color-success: #23c456;
$color-success-background: #e7fae6;
