/* -Block with links to social media
---------------------------------------------------------------------------- */
.block-social-media {
  margin-top: 40px;
  text-align: center;

  a {
    display: inline-block;
    margin: 0 10px;
    color: inherit;

    &:hover,
    &:focus {
      color: $color-action;
    }
  }
  .icon {
    width: 50px;
    height: 50px;
  }
}
