/* -Grid
-----------------------------------------------------------------------------*/
// Each group of grid items must be wrapped with a grid-wrap, which has a
// negative margin equal to the grid gutter. This makes them infinitely
// nestable; just use a new wrap with child items.
//
// Idea based on csswizardry-grids.

@use "sass:math";

$grid-gutter: 50px;
$grid-gutter-tight: 25px;
$breakpoint-grid-tight: em(600px);

@function grid-item-size($size) {
  @return $size * math.div(100%, 12);
}

// Mixins
// ----------------------------------------------------------------------------
@mixin when-tight-grid {
  @include media-max($breakpoint-grid-tight) {
    @content;
  }
}

@mixin grid-item-base {
  padding-left: $grid-gutter;

  @include when-tight-grid {
    padding-left: $grid-gutter-tight;
  }
}
@mixin grid-item($size) {
  @include grid-item-base;

  width: grid-item-size($size);
}

@mixin grid-item-size($size) {
  width: grid-item-size($size);
}

@mixin grid-wrap {
  display: flex;
  flex-wrap: wrap;
  margin-left: -$grid-gutter;

  @include when-tight-grid {
    margin-left: -$grid-gutter-tight;
  }
}

@mixin grid-wrap-size($size) {
  margin-left: -$size;
}
@mixin grid-item-gutter-size($size) {
  padding-left: $size;
}

@mixin grid-spacing-top-size($size) {
  margin-top: $size;
}
@mixin grid-spacing-bottom-size($size) {
  margin-bottom: $size;
}
@mixin grid-spacing-top {
  @include grid-spacing-top-size($grid-gutter);

  @include when-tight-grid {
    @include grid-spacing-top-size($grid-gutter-tight);
  }
}
@mixin grid-spacing-bottom {
  @include grid-spacing-bottom-size($grid-gutter);

  @include when-tight-grid {
    @include grid-spacing-bottom-size($grid-gutter-tight);
  }
}

@mixin grid-pull-bottom-size($size) {
  margin-bottom: -$size;
}
@mixin grid-pull-bottom {
  @include grid-pull-bottom-size($grid-gutter);

  @include when-tight-grid {
    @include grid-pull-bottom-size($grid-gutter-tight);
  }
}

@mixin grid-wrap-reset {
  display: block;
  flex-wrap: nowrap;
  margin-left: 0;
}
@mixin grid-item-reset {
  width: auto;
  padding-left: 0;
}

// No-flexbox versions
@mixin grid-wrap-classic {
  margin-left: -$grid-gutter;

  @include when-tight-grid {
    margin-left: -$grid-gutter-tight;
  }
}
@mixin grid-item-base-classic {
  display: inline-block;
  vertical-align: top;
  padding-left: $grid-gutter;

  @include when-tight-grid {
    padding-left: $grid-gutter-tight;
  }
}
@mixin grid-item-classic($size) {
  @include grid-item-base-classic;

  width: grid-item-size($size);
}
@mixin grid-wrap-classic-reset {
  margin-left: 0;
}
@mixin grid-item-classic-reset {
  display: block;
  width: auto;
  padding-left: 0;
}

// Misc. extends and classes
// ----------------------------------------------------------------------------
%grid-wrap {
  @include grid-wrap;
}
%grid-item-base {
  @include grid-item-base;
}
%grid-spacing-top {
  @include grid-spacing-top;
}
%grid-spacing-bottom {
  @include grid-spacing-bottom;
}
%grid-pull-bottom {
  @include grid-pull-bottom;
}

.grid-wrap {
  @include grid-wrap;
}
.grid-item-base {
  @include grid-item-base;
}
.grid-spacing-top {
  @include grid-spacing-top;
}
.grid-spacing-bottom {
  @include grid-spacing-bottom;
}
.grid-pull-bottom {
  @include grid-pull-bottom;
}

.grid-wrap--centered {
  text-align: center;
}

// Grid item extends and classes
// ----------------------------------------------------------------------------
@for $i from 1 through 12 {
  %grid-item-#{$i} {
    @extend %grid-item-base;
    width: grid-item-size($i);
  }
}
@for $i from 1 through 11 {
  %grid-item-offset-#{$i} {
    margin-left: grid-item-size($i);
  }
}
@for $i from 1 through 11 {
  %grid-item-push-#{$i} {
    margin-right: grid-item-size($i);
  }
}

.grid-item {
  @extend %grid-item-base;
}
@for $i from 1 through 12 {
  .grid-item--#{$i} {
    width: grid-item-size($i);
  }
}
@for $i from 1 through 11 {
  .grid-item-offset--#{$i} {
    @extend %grid-item-offset-#{$i};
  }
}
@for $i from 1 through 11 {
  .grid-item-push--#{$i} {
    @extend %grid-item-push-#{$i};
  }
}
