/* -Lazy loaded images
-----------------------------------------------------------------------------*/
.lazy-image {
  position: relative;
  height: 1px !important;

  img[src$='jpg'],
  img[data-src$='jpg'],
  .lazy-video {
    background: rgba(0, 0, 0, 0.05);
  }
}
.lazy-placeholder,
.lazy-video,
.lazy-image img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100% !important;
}
.lazy-placeholder {
  z-index: 1;
  transition: opacity 0.5s ease 1s;
  background: rgba(0, 0, 0, 0.05);

  img + & {
    opacity: 0;
  }
}
.lazy-image img {
  animation: fade-in 0.5s ease;
  z-index: 2;
}
