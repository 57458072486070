/* -Intro video component with video and possible text content
-----------------------------------------------------------------------------*/
.intro {
  position: relative;
  padding-top: 20px;
  padding-bottom: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  overflow: hidden;
  height: 56vw;

  // Ensure text is readable if video fails to load
  background-color: #c7c5c5;

  @include media-min(rem(600px)) {
    max-height: 70vh;
  }
}
.video-bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
}
video {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.intro-content {
  position: relative;
  width: 100%;
  color: #fff;
  text-align: center;
  z-index: 10;

  h1,
  h2,
  h3 {
    @extend %h2;
  }
  h1,
  h2,
  h3,
  p {
    color: inherit;
    text-shadow: 0 0 20px rgba(0, 0, 0, 0.7);

    @include media-max(rem(600px)) {
      font-size: rem(20px);
    }
  }
}
