// Slightly tweaked shadow mixins from Google's Material Design Lite

$shadow-key-umbra-opacity: 0.15;
$shadow-key-penumbra-opacity: 0.09;
$shadow-ambient-shadow-opacity: 0.08;

@mixin shadow-0() {
  box-shadow: none;
}
@mixin shadow-1() {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, $shadow-key-umbra-opacity),
    0px 1px 1px 0px rgba(0, 0, 0, $shadow-key-penumbra-opacity),
    0px 1px 3px 0px rgba(0, 0, 0, $shadow-ambient-shadow-opacity);
}
@mixin shadow-2() {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, $shadow-key-umbra-opacity),
    0px 2px 2px 0px rgba(0, 0, 0, $shadow-key-penumbra-opacity),
    0px 1px 5px 0px rgba(0, 0, 0, $shadow-ambient-shadow-opacity);
}
@mixin shadow-3() {
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, $shadow-key-umbra-opacity),
    0px 3px 4px 0px rgba(0, 0, 0, $shadow-key-penumbra-opacity),
    0px 1px 8px 0px rgba(0, 0, 0, $shadow-ambient-shadow-opacity);
}
@mixin shadow-4() {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, $shadow-key-umbra-opacity),
    0px 4px 5px 0px rgba(0, 0, 0, $shadow-key-penumbra-opacity),
    0px 1px 10px 0px rgba(0, 0, 0, $shadow-ambient-shadow-opacity);
}
@mixin shadow-5() {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, $shadow-key-umbra-opacity),
    0px 5px 8px 0px rgba(0, 0, 0, $shadow-key-penumbra-opacity),
    0px 1px 14px 0px rgba(0, 0, 0, $shadow-ambient-shadow-opacity);
}
@mixin shadow-6() {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, $shadow-key-umbra-opacity),
    0px 6px 10px 0px rgba(0, 0, 0, $shadow-key-penumbra-opacity),
    0px 1px 18px 0px rgba(0, 0, 0, $shadow-ambient-shadow-opacity);
}
@mixin shadow-7() {
  box-shadow: 0px 4px 5px -2px rgba(0, 0, 0, $shadow-key-umbra-opacity),
    0px 7px 10px 1px rgba(0, 0, 0, $shadow-key-penumbra-opacity),
    0px 2px 16px 1px rgba(0, 0, 0, $shadow-ambient-shadow-opacity);
}
@mixin shadow-8() {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, $shadow-key-umbra-opacity),
    0px 8px 10px 1px rgba(0, 0, 0, $shadow-key-penumbra-opacity),
    0px 3px 14px 2px rgba(0, 0, 0, $shadow-ambient-shadow-opacity);
}
@mixin shadow-9() {
  box-shadow: 0px 5px 6px -3px rgba(0, 0, 0, $shadow-key-umbra-opacity),
    0px 9px 12px 1px rgba(0, 0, 0, $shadow-key-penumbra-opacity),
    0px 3px 16px 2px rgba(0, 0, 0, $shadow-ambient-shadow-opacity);
}
@mixin shadow-10() {
  box-shadow: 0px 6px 6px -3px rgba(0, 0, 0, $shadow-key-umbra-opacity),
    0px 10px 14px 1px rgba(0, 0, 0, $shadow-key-penumbra-opacity),
    0px 4px 18px 3px rgba(0, 0, 0, $shadow-ambient-shadow-opacity);
}
@mixin shadow-11() {
  box-shadow: 0px 6px 7px -4px rgba(0, 0, 0, $shadow-key-umbra-opacity),
    0px 11px 15px 1px rgba(0, 0, 0, $shadow-key-penumbra-opacity),
    0px 4px 20px 3px rgba(0, 0, 0, $shadow-ambient-shadow-opacity);
}
@mixin shadow-12() {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, $shadow-key-umbra-opacity),
    0px 12px 17px 2px rgba(0, 0, 0, $shadow-key-penumbra-opacity),
    0px 5px 22px 4px rgba(0, 0, 0, $shadow-ambient-shadow-opacity);
}
@mixin shadow-13() {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, $shadow-key-umbra-opacity),
    0px 13px 19px 2px rgba(0, 0, 0, $shadow-key-penumbra-opacity),
    0px 5px 24px 4px rgba(0, 0, 0, $shadow-ambient-shadow-opacity);
}
@mixin shadow-14() {
  box-shadow: 0px 7px 9px -4px rgba(0, 0, 0, $shadow-key-umbra-opacity),
    0px 14px 21px 2px rgba(0, 0, 0, $shadow-key-penumbra-opacity),
    0px 5px 26px 4px rgba(0, 0, 0, $shadow-ambient-shadow-opacity);
}
@mixin shadow-15() {
  box-shadow: 0px 8px 9px -5px rgba(0, 0, 0, $shadow-key-umbra-opacity),
    0px 15px 22px 2px rgba(0, 0, 0, $shadow-key-penumbra-opacity),
    0px 6px 28px 5px rgba(0, 0, 0, $shadow-ambient-shadow-opacity);
}
@mixin shadow-16() {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, $shadow-key-umbra-opacity),
    0px 16px 24px 2px rgba(0, 0, 0, $shadow-key-penumbra-opacity),
    0px 6px 30px 5px rgba(0, 0, 0, $shadow-ambient-shadow-opacity);
}
@mixin shadow-17() {
  box-shadow: 0px 8px 11px -5px rgba(0, 0, 0, $shadow-key-umbra-opacity),
    0px 17px 26px 2px rgba(0, 0, 0, $shadow-key-penumbra-opacity),
    0px 6px 32px 5px rgba(0, 0, 0, $shadow-ambient-shadow-opacity);
}
@mixin shadow-18() {
  box-shadow: 0px 9px 11px -5px rgba(0, 0, 0, $shadow-key-umbra-opacity),
    0px 18px 28px 2px rgba(0, 0, 0, $shadow-key-penumbra-opacity),
    0px 7px 34px 6px rgba(0, 0, 0, $shadow-ambient-shadow-opacity);
}
@mixin shadow-19() {
  box-shadow: 0px 9px 12px -6px rgba(0, 0, 0, $shadow-key-umbra-opacity),
    0px 19px 29px 2px rgba(0, 0, 0, $shadow-key-penumbra-opacity),
    0px 7px 36px 6px rgba(0, 0, 0, $shadow-ambient-shadow-opacity);
}
@mixin shadow-20() {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, $shadow-key-umbra-opacity),
    0px 20px 31px 3px rgba(0, 0, 0, $shadow-key-penumbra-opacity),
    0px 8px 38px 7px rgba(0, 0, 0, $shadow-ambient-shadow-opacity);
}
@mixin shadow-21() {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, $shadow-key-umbra-opacity),
    0px 21px 33px 3px rgba(0, 0, 0, $shadow-key-penumbra-opacity),
    0px 8px 40px 7px rgba(0, 0, 0, $shadow-ambient-shadow-opacity);
}
@mixin shadow-22() {
  box-shadow: 0px 10px 14px -6px rgba(0, 0, 0, $shadow-key-umbra-opacity),
    0px 22px 35px 3px rgba(0, 0, 0, $shadow-key-penumbra-opacity),
    0px 8px 42px 7px rgba(0, 0, 0, $shadow-ambient-shadow-opacity);
}
@mixin shadow-23() {
  box-shadow: 0px 11px 14px -7px rgba(0, 0, 0, $shadow-key-umbra-opacity),
    0px 23px 36px 3px rgba(0, 0, 0, $shadow-key-penumbra-opacity),
    0px 9px 44px 8px rgba(0, 0, 0, $shadow-ambient-shadow-opacity);
}
@mixin shadow-24() {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, $shadow-key-umbra-opacity),
    0px 24px 38px 3px rgba(0, 0, 0, $shadow-key-penumbra-opacity),
    0px 9px 46px 8px rgba(0, 0, 0, $shadow-ambient-shadow-opacity);
}
