/* -Office users (office/contact persons)
-----------------------------------------------------------------------------*/
.office-users {
  @include section-margin;
  @include grid-wrap;

  justify-content: center;
}

.office-user {
  @include grid-item(4);
  @include grid-spacing-bottom;

  .office-users--cols-4 & {
    @include grid-item(3);
  }

  text-align: center;

  p {
    margin: 1px 0;
  }
  img {
    border-radius: 50%;
  }
}

.office-user--full {
  .office-user-name {
    margin: 10px 0 5px;
    @include font-size(24px);

    @include media-max(em(800px)) {
      @include font-size(18px);
    }
  }
  .office-user-image {
    max-width: 240px;
    margin: 0 auto;

    @include media-max($breakpoint-grid-tight) {
      padding: 0 15px;
    }
    @include media-max(em(400px)) {
      max-width: 180px;
    }
  }
  @include media-max(em(700px)) {
    @include grid-item-size(6);

    max-width: 250px;
  }
  @include media-max(em(1000px)) {
    .office-users--cols-4 & {
      @include grid-item-size(6);

      max-width: 350px;
    }
  }
  @include media-max(em(450px)) {
    @include grid-item-size(12);

    .office-users--cols-4 & {
      @include grid-item-size(12);
    }
  }
}

.office-user--lite {
  margin-left: auto;
  margin-right: auto;

  .office-user-name {
    margin: 5px 0 10px;
    text-transform: none;
    color: $color-gold;
  }

  @include media-max(em(650px)) {
    .office-user-image {
      max-width: 120px;
      margin-left: auto;
      margin-right: auto;
    }
    &.office-users--cols-4 {
      max-width: 400px;
    }
    .office-users--cols-4 & {
      @include grid-item-size(6);
    }
  }
}
