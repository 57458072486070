/* -Main site navigation menu
-----------------------------------------------------------------------------*/
.site-nav {
  li {
    margin-left: 15px;
  }
  a {
    padding: 5px 2px;
    text-transform: uppercase;
    letter-spacing: 0.5px;
    color: $color-brand-blue;
    text-decoration: none;
    border-bottom: 2px solid transparent;

    &.active {
      border-bottom-color: $color-site-nav-border;
    }
    &:hover,
    &:focus {
      border-bottom-color: $color-action;
    }
  }
}

@include media-max($breakpoint-header-stacked) {
  .site-nav {
    padding: 10px 0;
    background: $color-site-header-border;
    color: #fff;

    li {
      margin: 0 8px;
    }
    a {
      color: inherit;

      &:hover,
      &:focus,
      &.active {
        border-bottom-color: #fff;
      }
    }
  }
}
@include media-max(em(400px)) {
  .site-nav {
    @include font-size(13px);
  }
}
