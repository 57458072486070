/* -Headings
-----------------------------------------------------------------------------*/
@mixin h {
  margin-bottom: 15px;
  color: $color-gold;
  line-height: 1.25;
  font-family: $font-stack-alt;
  font-weight: $font-weight-regular;
  font-style: italic;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  @include h;
}

.rich-text {
  h1 ~,
  h2 ~,
  h3 ~ {
    h2,
    h3 {
      @extend %h4;

      max-width: rem($wrap-width-extra-thin);
      margin-left: auto;
      margin-right: auto;
      color: inherit;
      font-style: normal;
      line-height: 1.5;
    }
  }
}

%h1 {
  @include font-size($h1);
}
%h2 {
  @include font-size($h2);
}
%h3 {
  @include font-size($h3);
}
%h4 {
  @include font-size($h4);
}
%h5 {
  @include font-size($h5);
}
%h6 {
  @include font-size($h6);
}

h1,
.h1 {
  @extend %h1;
}
h2,
.h2 {
  @extend %h2;
}
h3,
.h3 {
  @extend %h3;
}
h4,
.h4 {
  @extend %h4;

  &.h-alt {
    font-weight: $font-weight-regular;
  }
}
h5,
.h5 {
  @extend %h5;

  &.h-alt {
    font-weight: $font-weight-regular;
  }
}
h6,
.h6 {
  @extend %h6;

  &.h-alt {
    font-weight: $font-weight-regular;
  }
}

.h-alt {
  color: inherit;
  font-family: $font-stack-main;
  font-weight: $font-weight-light;
  font-style: normal;
  text-transform: uppercase;
}

.h-plain {
  margin: 0;
  color: inherit;
  font-family: inherit;
  font-size: inherit;
  font-weight: inherit;
  font-style: inherit;
  text-transform: inherit;
}

@include media-max($breakpoint-type-medium) {
  %h1 {
    @include font-size($h1 - 10px);
  }
  %h2 {
    @include font-size($h2 - 4px);
  }
  %h3 {
    @include font-size($h3 - 2px);
  }
}

@include media-max($breakpoint-type-small) {
  %h1 {
    @include font-size($h1 - 24px);
  }
  %h2 {
    @include font-size($h2 - 12px);
  }
  %h4 {
    @include font-size(1em);
  }
}
