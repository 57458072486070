$page-margin: em(40px);

$section-spacing: 80px;
$section-spacing-small: 40px;

$wrap-width-wide: em(1400px);
$wrap-width: em(980px);
$wrap-width-medium: em(1200px);
$wrap-width-thin: em(850px);
$wrap-width-extra-thin: em(700px);

// Form elements
$form-field-padding: em(5px);
$form-control-height: em(35px);
$form-control-radius: 0;
$form-control-font-size: 18px;

// Reduce text sizes
$breakpoint-type-medium: em(1200px);
$breakpoint-type-small: em(500px);

$breakpoint-page-margin-small: em(600px);

$breakpoint-header-stacked: em(750px);
