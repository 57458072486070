/* -General flexbox layout helpers
-----------------------------------------------------------------------------*/
.flex-grid-wrap {
  display: flex;
}
.flex-grid-wrap--inline {
  display: inline-flex;
}

.flex-grid-wrap--left {
  justify-content: flex-start;
}
.flex-grid-wrap--center {
  justify-content: center;
}
.flex-grid-wrap--right {
  justify-content: flex-end;
}

.flex-grid-wrap--top {
  align-items: flex-start;
}
.flex-grid-wrap--middle {
  align-items: center;
}
.flex-grid-wrap--bottom {
  align-items: flex-end;
}

.flex-grid-item--grow {
  flex-grow: 1;
}
.flex-grid-item--shrink {
  flex-shrink: 1;
}
