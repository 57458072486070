// Mixins
// ----------------------------------------------------------------------------

@mixin clearfix {
  &:before,
  &:after {
    content: ' ';
    display: table;
  }
  &:after {
    clear: both;
  }
}

@mixin flip {
  transform: scaleX(-1);
}

@mixin uppercase {
  text-transform: uppercase;
  letter-spacing: 0.03em;
}

@mixin wrap {
  max-width: $wrap-width;
  margin-left: auto;
  margin-right: auto;
  padding-left: $page-margin;
  padding-right: $page-margin;
}

// Arrow (not triangle), should be included in a pseudo element that preferably
// checks for CSS transforms support via Modernizr class
@mixin pseudo-arrow($size, $thickness: 2px) {
  content: '';
  display: block;
  position: absolute;
  top: 50%;
  width: $size;
  height: $size;
  margin-top: -(floor($size * 0.5));
  border-top: $thickness solid;
  border-right: $thickness solid;
  @include transform(rotate(45deg));
}

// Font size in rem with px fallback
@mixin font-size($size) {
  @if unit($size) == 'em' {
    $size: px($size);
  }
  font-size: $size;
  font-size: rem($size);
}

// Screen reader accessible text, from HTML5 Boilerplate
@mixin visuallyhidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}
@mixin non-visuallyhidden {
  clip: auto;
  height: auto;
  margin: 0;
  overflow: visible;
  position: static;
  width: auto;
}

@mixin vertical-align {
  top: 50%;
  @include transform(translateY(-50%));
}
@mixin vertical-align-relative {
  position: relative;
  @include vertical-align;
}
@mixin vertical-align-absolute {
  position: absolute;
  @include vertical-align;
}

// Center an image (or other content) of unknown size and proportions
// horizontally and/or vertically. Use the mixin on a container that has a span
// as a direct child, with the image inside said span.
// Works by creating a square with percentage padding and positioning a child
// span absolutely inside. The span's content is then centered with text-align
// and vertical-align.
@mixin square-centered {
  display: block;
  position: relative;

  // The 100% padding will collapse any small height, so set something higher
  // than zero to prevent screen readers from being smart and interpreting tiny
  // height as hidden content.
  height: 10px;

  // Percentage padding is calculated based on the width of the containing
  // element, so 100% will mean the full width of the parent. With box-sizing
  // border-box, the above small height will collapse and the block will always
  // be a square, no matter the image proportions. If the element with this mixin
  // isn't full width, match the bottom padding to the set width.
  padding-bottom: 100%;

  > span {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    vertical-align: middle;
    text-align: center;

    // 'Ghost element' vertical centering
    // http://css-tricks.com/centering-in-the-unknown/
    &:before {
      content: '';
      display: inline-block;
      width: 0;
      height: 100%;
      vertical-align: middle;
    }
  }
  img {
    max-width: 100%;
    max-height: 100%;
  }
}

// Quantity queries
@mixin when-at-least($min) {
  &:nth-last-child(n + #{$min}),
  &:nth-last-child(n + #{$min}) ~ & {
    @content;
  }
}
@mixin when-at-most($max) {
  &:nth-last-child(-n + #{$max}):first-child,
  &:nth-last-child(-n + #{$max}):first-child ~ & {
    @content;
  }
}
@mixin when-between($min, $max) {
  &:nth-last-child(n + #{$min}):nth-last-child(-n + #{$max}):first-child,
  &:nth-last-child(n + #{$min}):nth-last-child(-n + #{$max}):first-child ~ & {
    @content;
  }
}

@mixin join-parent($el) {
  @at-root #{$el}#{&} {
    @content;
  }
}

@mixin double-parent() {
  @at-root #{&}#{&} {
    @content;
  }
}

// Media queries
@mixin media-max($max) {
  @media screen and (max-width: $max) {
    @content;
  }
}
@mixin media-min($min) {
  @media screen and (min-width: $min) {
    @content;
  }
}
@mixin media-between($max, $min) {
  @media screen and (max-width: $max) and (min-width: $min) {
    @content;
  }
}

// Consistent section and block spacing
@mixin section-margin($times: 1) {
  margin-top: $section-spacing * $times;
  margin-bottom: $section-spacing * $times;

  @include media-max($breakpoint-page-margin-small) {
    margin-top: $section-spacing-small * $times;
    margin-bottom: $section-spacing-small * $times;
  }
}
@mixin section-margin-top($times: 1) {
  margin-top: $section-spacing * $times;

  @include media-max($breakpoint-page-margin-small) {
    margin-top: $section-spacing-small * $times;
  }
}
@mixin section-margin-bottom($times: 1) {
  margin-bottom: $section-spacing * $times;

  @include media-max($breakpoint-page-margin-small) {
    margin-bottom: $section-spacing-small * $times;
  }
}
@mixin section-padding($times: 1) {
  padding-top: $section-spacing * $times;
  padding-bottom: $section-spacing * $times;

  @include media-max($breakpoint-page-margin-small) {
    padding-top: $section-spacing-small * $times;
    padding-bottom: $section-spacing-small * $times;
  }
}
@mixin section-padding-top($times: 1) {
  padding-top: $section-spacing * $times;

  @include media-max($breakpoint-page-margin-small) {
    padding-top: $section-spacing-small * $times;
  }
}
@mixin section-padding-bottom($times: 1) {
  padding-bottom: $section-spacing * $times;

  @include media-max($breakpoint-page-margin-small) {
    padding-bottom: $section-spacing-small * $times;
  }
}
