/* -Form validation styling
-----------------------------------------------------------------------------*/
.input-error,
.form-error,
.input-success,
.form-success,
.input-error > .Select-control {
  background-repeat: no-repeat;
  background-size: em(24px) em(16px);
}
.input-error,
.form-error,
.input-error > .Select-control {
  background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIxNiIgdmlld0JveD0iMCAwIDI0IDE2Ij48cGF0aCBmaWxsPSIjRjI0MTREIiBkPSJNOCAxLjRsNi43IDEzLjRIMS4zTDggMS40ek04IDBjLS4zIDAtLjcuMi0xIC43TC4yIDE0LjNjLS41LjktLjEgMS43IDEgMS43aDEzLjZjMS4xIDAgMS41LS44IDEtMS43TDkgLjdDOC43LjIgOC4zIDAgOCAweiIvPjxwYXRoIGZpbGw9IiNGMjQxNEQiIGQ9Ik05IDEzYzAgLjYtLjQgMS0xIDFzLTEtLjQtMS0xIC40LTEgMS0xIDEgLjQgMSAxek04IDExYy0uNiAwLTEtLjQtMS0xVjdjMC0uNi40LTEgMS0xczEgLjQgMSAxdjNjMCAuNi0uNCAxLTEgMXoiLz48L3N2Zz4=);
}
.input-success,
.form-success {
  background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyMCIgaGVpZ2h0PSIxMiIgdmlld0JveD0iMCAwIDIwIDEyIj48cGF0aCBmaWxsPSIjMjNDNDU2IiBkPSJNMTAuMyAxLjFsMS42IDEuM2MuMS4xLjEuMiAwIC4zbC02LjkgOC4yYzAgLjEtLjEuMS0uMi4xcy0uMSAwLS4xLS4xbC00LjYtMy44Yy0uMSAwLS4xLS4yIDAtLjNsMS4zLTEuNmMuMS0uMS4yLS4xLjMgMGwyLjggMi4zIDUuNS02LjRjLjEtLjEuMi0uMS4zIDB6Ii8+PC9zdmc+);
}

/* Input fields */
/* Specificity: default styles use attribute selectors (i.e. input[type="text"]),
which requires more than just a class name to override */
body {
  .input-error,
  .input-success {
    &,
    & > .Select-control {
      background-position: 100% 50%;
      padding-right: $form-field-padding + em(25px);
    }
  }
  .input-error {
    &,
    & > .Select-control {
      border-color: $color-error !important;
      background-color: $color-error-background;
      color: darken($color-error, 20%);
    }
  }
  .input-success {
    &,
    & > .Select-control {
      border-color: $color-success;
      background-color: $color-success-background;
      color: darken($color-success, 20%);
    }
  }
}
textarea.input-error,
textarea.input-success {
  background-position: 100% 10px;
}
.input-error:focus,
.input-success:focus {
  padding-right: $form-field-padding;
  background: #fff;
  color: $color-body-foreground;
}

/* Form message */
.form-error,
.form-success {
  display: block;
  margin-bottom: 0.75em;
  padding: $form-field-padding ($form-field-padding + em(30px));
  border: 1px solid;
  background-position: 10px 50%;
  @include font-size(14px);
  text-align: center;
  text-shadow: none;
}
.form-error {
  border-color: lighten($color-error, 20%);
  background-color: $color-error-background;
  color: darken($color-error, 20%);
}
.form-success {
  border-color: lighten($color-success, 20%);
  background-color: $color-success-background;
  color: darken($color-success, 20%);
}

/* Field message */
.field-error,
.field-success {
  animation: fade-in 0.2s, scale-up 0.2s;
  transition: color 0.15s ease;
  @include font-size(14px);

  .icon {
    margin-top: -1px;
    margin-right: 5px;
  }
}
.field-error {
  color: darken($color-error, 10%);
}
.field-success {
  color: darken($color-success, 10%);
}
.field-error--inactive,
.field-success--inactive {
  color: $color-lightest-foreground-on-white;
}
