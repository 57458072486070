/* -Overlay and modal
-----------------------------------------------------------------------------*/
$modal-animation-duration: 0.25s;

.modal-backdrop,
.modal-inner {
  display: none;
}
.modal-wrap .is-open {
  .modal-backdrop,
  .modal-inner {
    animation: fade-in $modal-animation-duration;
    display: block;
  }
  .modal-inner {
    animation: fade-in $modal-animation-duration,
      scale-up $modal-animation-duration;
    display: block;
  }
}
.modal-wrap--inline {
  display: inline-block;
}
.modal-wrap--closing {
  .modal-backdrop,
  .modal-inner {
    transition: transform $modal-animation-duration ease,
      opacity $modal-animation-duration ease;
    opacity: 0;
  }
  .modal-inner {
    transform: scale(0.9);
  }
}
.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 25px 20px;
  background: rgba(0, 0, 0, 0.5);
  z-index: $z-modal;
}
.modal,
.modal-inner,
.modal-content {
  outline: 0;
}
.modal {
  position: relative;
  text-align: left;
  z-index: $z-modal + 1;
}
.modal-inner {
  position: relative;
  transform-origin: 50% 50%;
  padding: 20px;
  background: #fff;
}
.modal-title {
  margin-top: -5px;
  padding-right: 30px;
}
.modal-content {
  overflow: auto;
}
.modal-close {
  position: absolute;
  top: 0;
  right: 0;
  width: 50px;
  height: 50px;
  z-index: $z-modal + 2;
}

/* No flexbox fallback */
.modal,
.modal-inner,
.modal-content {
  height: 100%;
}

// .flexbox {
.modal {
  display: flex;
}
.modal--centered {
  align-items: center;
}
.modal-inner {
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
}
.modal-inner,
.modal-content {
  height: auto;
  max-height: 100%;
}
.modal-wrap .is-open {
  .modal-backdrop,
  .modal-inner {
    display: flex;
  }
  .modal-inner {
    display: flex;
  }
}
// }
