/* -Blurb component - block with a linked image
-----------------------------------------------------------------------------*/
$blurb-hover-opacity: 0.4;

.blurb-list-wrap {
  @include section-margin;
}
.blurb-item {
  position: relative;
}
.blurb__inner {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px;
  min-height: 200px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50% 50%;
}
.overlay-link {
  &:hover,
  &:focus {
    ~ .blurb__inner {
      a {
        background-color: rgba(0, 0, 0, $blurb-hover-opacity);
      }
    }
  }
}

.blurb__link {
  text-align: center;

  p {
    margin-bottom: 0;
  }

  a {
    @include h;
    @extend %h3;

    display: block;
    padding: 5px 20px 10px 20px;
    background-color: rgb(0, 0, 0, 0.3);
    border: 3px solid #fff;
    color: #fff;
    transition: background-color 0.15s ease;
    word-break: break-all;
    text-decoration: none;
    font-style: normal;

    &:hover,
    &:focus {
      outline: 3px dotted currentColor;
      background-color: rgb(0, 0, 0, $blurb-hover-opacity);
    }
  }
}

// List of blurbs
$blurb-gutter: 0;
@include media-min(rem(600px)) {
  .blurb-list {
    @include grid-wrap;
    @include grid-wrap-size($blurb-gutter);

    &.blurb-list--cols-1 {
      justify-content: center;
    }
  }
  .blurb-item {
    @include grid-item(6);
    @include grid-item-gutter-size($blurb-gutter);
  }
  .blurb__inner {
    min-height: 250px;
  }
}

@include media-min(rem(800px)) {
  .blurb__inner {
    min-height: 350px;
  }
}

@include media-min(rem(1000px)) {
  .blurb-list--cols-3 {
    .blurb-item {
      @include grid-item-size(4);
    }
  }
  .blurb-list--cols-2,
  .blurb-list--cols-3,
  .blurb-list--cols-4 {
    .blurb__inner {
      min-height: 400px;
    }
  }
}

@include media-min(rem(1100px)) {
  .blurb-list--cols-3,
  .blurb-list--cols-4 {
    .blurb__inner {
      min-height: 300px;
    }
  }
  .blurb-list--cols-4 {
    .blurb-item {
      @include grid-item-size(3);
    }
  }
}
