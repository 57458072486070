/* -Office (company) information
-----------------------------------------------------------------------------*/
.office-info {
  p {
    margin: 1px 0;
  }
}
.office-info-title {
  margin: 0 0 5px;
}
