/* -Links
-----------------------------------------------------------------------------*/
a:link,
a:visited {
  color: $color-action;
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0.2);
}
a:hover,
a:focus {
  color: $color-action-active;
  text-decoration: none;
  outline: thin dotted;
}
a:hover,
a:active {
  outline: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  a {
    text-decoration: none;
  }
}

a {
  .icon,
  .text {
    vertical-align: middle;
  }
  .icon {
    &:first-child {
      margin-right: 5px;
    }
    &:last-child {
      margin-left: 5px;
    }
    &:first-child:last-child {
      margin-left: 0;
      margin-right: 0;
    }
  }
}

a.document-link {
  text-decoration: none;

  .text {
    text-decoration: underline;
  }
  &:hover,
  &:focus {
    .text {
      text-decoration: none;
    }
  }
}

a.chevron-link {
  @include font-size(18px);
  text-decoration: none;
  white-space: nowrap;

  .icon,
  .text {
    vertical-align: middle;
  }
  .icon {
    margin-left: 10px;
  }
  .text {
    white-space: normal;
  }
  &:hover,
  &:focus {
    .text {
      text-decoration: underline;
    }
  }
}

a.skiplink {
  display: block;
  clear: both;
  top: 0;
  left: 0;
  background: $color-action;
  color: $color-action-foreground;
  text-align: center;
  z-index: $z-skiplink;

  &:focus,
  &:active {
    position: absolute !important;
    padding: 0.25em 0.75em;
    text-decoration: none;
  }
}
a.skiplink-top {
  top: auto;
  left: auto;
  right: 0;
  bottom: 0;
}

%overlay-link {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  text-indent: -800px;
  font-size: 12px;
  z-index: $z-overlay-link;

  /* A 10x10 transparent PNG; a fix for the link not being clickable in IE */
  background: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAoAAAAKCAYAAACNMs+9AAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAABdJREFUeNpi/P//PwMxgHFUIX0VAgQYACK+He3OQHX/AAAAAElFTkSuQmCC') !important;
}
.overlay-link {
  @extend %overlay-link;
}
