/* -Estate type label
-----------------------------------------------------------------------------*/
.estate-type-label {
  @include h;

  position: absolute;
  top: 0;
  right: 0;
  transform: translateX(30%) translateY(0%) rotate(45deg);
  transform-origin: top left;
  padding: 8px 5px 10px 5px;
  color: #fff;
  text-align: center;
  z-index: 5;

  // Fix to get rid of unwanted outline after rotate.
  backface-visibility: hidden;

  &:before,
  &:after {
    content: '';
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
  }
  &:before {
    right: 100%;
  }
  &:after {
    left: 100%;
  }
}
.estate-type-label--discrete {
  &,
  &:before,
  &:after {
    background-color: rgba($color-blue, 0.8);
  }
}

@include media-max(rem(375px)) {
  .estate-type-label {
    .estate-main-intro & {
      font-size: rem(18px);
      padding: 2px 0 4px 0;
    }
  }
}

@include media-between(rem(475px), rem(375px)) {
  .estate-type-label {
    .estate-main-intro & {
      font-size: rem(20px);
      padding: 5px 0 7px 0;
    }
  }
}
