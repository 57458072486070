/* -Icons
-----------------------------------------------------------------------------*/
// Previously generated with grunt, should possibly be replaced with something
// else.

/* Base */
.icon {
  display: inline-block;
  vertical-align: middle;

  svg {
    display: inline-block;
    width: 100%;
    height: 100%;
    vertical-align: top;
    fill: currentColor;
  }
}

/* Shapes */
$icon-check-width: 20px;
$icon-check-height: 15.6px;
.icon-check {
  width: 20px;
  height: 15.6px;
}

$icon-chevron-down-width: 11.3px;
$icon-chevron-down-height: 7.1px;
.icon-chevron-down {
  width: 11.3px;
  height: 7.1px;
}

$icon-chevron-left-width: 7.1px;
$icon-chevron-left-height: 11.3px;
.icon-chevron-left {
  width: 7.1px;
  height: 11.3px;
}

$icon-chevron-left-large-width: 9px;
$icon-chevron-left-large-height: 16px;
.icon-chevron-left-large {
  width: 9px;
  height: 16px;
}

$icon-chevron-right-width: 7.1px;
$icon-chevron-right-height: 11.3px;
.icon-chevron-right {
  width: 7.1px;
  height: 11.3px;
}

$icon-chevron-right-large-width: 9px;
$icon-chevron-right-large-height: 16px;
.icon-chevron-right-large {
  width: 9px;
  height: 16px;
}

$icon-document-width: 20px;
$icon-document-height: 25px;
.icon-document {
  width: 20px;
  height: 25px;
}

$icon-document-pdf-width: 20px;
$icon-document-pdf-height: 25px;
.icon-document-pdf {
  width: 20px;
  height: 25px;
}

$icon-envelope-width: 20px;
$icon-envelope-height: 16.2px;
.icon-envelope {
  width: 20px;
  height: 16.2px;
}

$icon-error-width: 16px;
$icon-error-height: 16px;
.icon-error {
  width: 16px;
  height: 16px;
}


$icon-facebook-width: 20px;
$icon-facebook-height: 20px;
.icon-facebook {
  width: 20px;
  height: 20px;
}

$icon-instagram-width: 20px;
$icon-instagram-height: 20px;
.icon-instagram {
  width: 20px;
  height: 20px;
}

$icon-location-width: 12.5px;
$icon-location-height: 20px;
.icon-location {
  width: 12.5px;
  height: 20px;
}

$icon-phone-width: 20px;
$icon-phone-height: 20px;
.icon-phone {
  width: 20px;
  height: 20px;
}

$icon-warning-width: 16px;
$icon-warning-height: 16px;
.icon-warning {
  width: 16px;
  height: 16px;
}
