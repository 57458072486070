/* -Overlay component
-----------------------------------------------------------------------------*/
$overlay-animation-duration: 0.25s;

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #fff;
}
.overlay--animated {
  animation: fade-in $overlay-animation-duration;
}
