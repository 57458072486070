/* -Global layout and styles
-----------------------------------------------------------------------------*/
body,
#main {
  background: $color-body-background;
}
body {
  scroll-behavior: smooth;
}
.app-view-edit {
  background: #fff;
}

.page-container {
  display: flex;
  flex-direction: column;
  min-height: calc(100vh - 20px);
}
.page-content {
  flex-grow: 1;
}

/* Container for DOM elements as HTML drag-and-drop drag image. Browsers are
picky with what they show and not.
http://www.kryogenix.org/code/browser/custom-drag-image.html */
#main {
  position: relative;
  z-index: 2;
}
#drag-image-container {
  position: absolute;
  top: 100px;
  left: 100px;
  z-index: 1;
}
