/* -A distinct section of a page
-----------------------------------------------------------------------------*/
$page-area-padding: 80px;
$page-area-padding-medium: 60px;
$page-area-padding-small: 40px;

.page-area {
  padding: $page-area-padding 0;
  background-size: cover;
  background-position: 50% 50%;

  @include media-max(em(600px)) {
    padding: $page-area-padding-medium 0;
  }
  @include media-max(em(400px)) {
    padding: $page-area-padding-small 0;
  }
}
.page-area--gray {
  background-color: $color-gray-light;
}
.page-area--cream {
  background-color: $color-cream;
}

/* Reduce spacing on adjacent areas of the same color */
@each $color in 'white', 'gray', 'cream' {
  .page-area--#{$color} {
    & + & {
      padding-top: 0;
    }
  }
}
