/* -Base
-----------------------------------------------------------------------------*/
html {
  font-size: $base-font-size;
}
body {
  color: $color-body-foreground;
  font-size: 1em;
  line-height: $base-line-height;
  tab-size: 4;
}
body,
button,
input,
optgroup,
select,
textarea {
  font-family: $font-stack-main;
  color: $color-body-foreground;
}

::selection {
  background: $color-selection;
  color: #fff;
  text-shadow: none;
}

/* -Text element spacing
-----------------------------------------------------------------------------*/
p,
blockquote,
dl,
ol,
ul,
pre,
table,
.pdiv {
  margin-bottom: $text-spacing;
}
blockquote {
  margin-left: $text-spacing;
  margin-right: $text-spacing;
}
dl dd,
dl dl {
  margin-left: $text-spacing;
}

li ul,
li ol {
  margin-bottom: 0;
}

ul {
  list-style: disc outside;
  margin-left: 1em;
}
ul ul {
  list-style-type: square;
  margin-left: $text-spacing;
}
ul ul ul {
  list-style-type: circle;
}
ol {
  list-style: decimal outside;
  margin-left: 1.5em;
}
ol ol {
  list-style-type: upper-roman;
  margin-left: $text-spacing;
}
ol ol ol {
  list-style-type: upper-alpha;
}

nav ul,
nav ol {
  margin: 0;
  list-style: none;
  list-style-image: none;
}

/* -Text element styles
-----------------------------------------------------------------------------*/
blockquote,
q {
  quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
  content: '';
  content: none;
}

abbr[title],
dfn[title] {
  border-bottom: 1px dotted;
  text-decoration: none;
  cursor: help;
}
blockquote,
cite,
dfn,
q {
  font-style: italic;
}
b,
strong,
dt,
mark,
th {
  font-weight: $font-weight-bold;
}
del {
  color: lighten($color-body-foreground, 20%);
  text-decoration: line-through;
}
ins,
mark {
  background: #ffa;
  color: $color-body-foreground;
  text-decoration: none;
}
pre,
code,
kbd,
samp {
  font-family: $font-stack-mono;
  font-size: em(14px);
}
pre {
  white-space: pre;
  white-space: pre-wrap;
  word-wrap: break-word;
  overflow: auto;
}
small {
  font-size: em(12px);
}
sub,
sup {
  font-size: em(12px);
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}
sup {
  top: -0.5em;
}
sub {
  bottom: -0.25em;
}

/* -Styles
-----------------------------------------------------------------------------*/

/* Larger and smaller text */
%lead-text {
  margin-bottom: em($base-text-spacing, $lead-text-size);
  @include font-size($lead-text-size);
  font-weight: $font-weight-light;

  @include media-max($breakpoint-type-small) {
    @include font-size($base-font-size + 2px);
  }
}
%lead-text-alt {
  @include h;
  @include font-size($lead-text-size);

  max-width: rem($wrap-width-extra-thin);
  margin-left: auto;
  margin-right: auto;
  color: inherit;
  font-style: normal;
  line-height: 1.5;

  @include media-max($breakpoint-type-small) {
    @include font-size($base-font-size + 2px);
  }
}
%sub-text {
  margin-bottom: em($base-text-spacing, $sub-text-size);
  @include font-size($sub-text-size);
}
.lead-text {
  @extend %lead-text;
}
.lead-text-alt {
  @extend %lead-text-alt;
}
.sub-text {
  @extend %sub-text;
}

@mixin quote-text {
  margin-bottom: 0;
  text-align: center;
  font-family: $font-stack-alt;
  font-style: italic;
  font-weight: $font-weight-bold;
  font-size: rem($quote-text-size - 10px);
  color: $color-quote-foreground;

  &:before,
  &:after {
    content: '"';
  }

  @include media-min($breakpoint-type-small) {
    font-size: rem($quote-text-size - 8px);
  }
  @include media-min($breakpoint-type-medium) {
    font-size: rem($quote-text-size);
  }
}
.quote-text {
  @include quote-text;
}

/* General use next to icons, for vertical alignment */
span.text {
  vertical-align: middle;
}
