/* -Instagram
-----------------------------------------------------------------------------*/
.instagram-images {
  @include grid-wrap;
}
.instagram-image {
  @include grid-item(4);
  max-width: 350px;
}

.instagram-image-text {
  margin-top: 10px;
  word-wrap: break-word;
}
.instagram-image-date {
  max-width: 80%;
  margin: 0 auto;
  border-top: 1px solid $color-brand-blue-soft;
  text-align: center;
  font-family: $font-stack-alt;
  font-style: italic;
  color: $color-lightest-foreground-on-white;
}

@include media-max(em(550px)) {
  .instagram-images {
    @include grid-wrap-reset;
  }
  .instagram-image {
    @include grid-item-reset;
    margin: 0 auto 40px;
  }
}
