/* -Information boxes
-----------------------------------------------------------------------------*/
.message-box {
  margin: 1em 0;
  padding: 10px 15px;

  p,
  ol,
  ul {
    margin-top: 5px !important;
    margin-bottom: 0 !important;

    &:first-child {
      margin-top: 0 !important;
    }
  }
  small {
    @include font-size(12px);
  }
  .icon {
    float: left;
    margin-top: 0.25em;
    margin-right: 0.5em;
  }
}
.message-box-content {
  overflow: hidden;
}

.message-box-title {
  margin: 0 !important;
  @include font-size(20px);
  font-weight: $font-weight-bold;
}

.message-box--info {
  background: $color-message-info-background;
  color: $color-message-info-foreground;
}
.message-box--warning {
  background: $color-message-warning-background;
  color: $color-message-warning-foreground;
}
.message-box--error {
  background: $color-message-error-background;
  color: $color-message-error-foreground;
}
.message-box--success {
  background: $color-message-success-background;
  color: $color-message-success-foreground;
}

.message-box--light {
  background: #fff;
}
.message-box--info--light {
  border: 1px solid lighten($color-message-info-foreground, 40%);
  color: $color-message-info-foreground;
}
.message-box--warning--light {
  border: 1px solid lighten($color-message-warning-foreground, 40%);
  color: $color-message-warning-foreground;
}
.message-box--error--light {
  border: 1px solid lighten($color-message-error-foreground, 40%);
  color: $color-message-error-foreground;
}
.message-box--success--light {
  border: 1px solid lighten($color-message-success-foreground, 40%);
  color: $color-message-success-foreground;
}

.message-box--plain {
  background: transparent;
  text-align: center;
}

.message-box--large {
  padding: 20px 25px;
}
