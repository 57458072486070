/* -Text with loading dots
-----------------------------------------------------------------------------*/
.loading-text {
  display: inline-block;
  text-align: center;
}
.loading-text--block {
  display: block;
  margin-top: 20px;
  margin-bottom: 20px;
}
.loading-text--page {
  display: block;
  margin-top: 30px;
  margin-bottom: 200px;
  height: 40vh;
  line-height: 40vh;
}
.loading-text-dots {
  display: inline-block;
  width: 1em;
  text-align: left;
}

.loading-text--tiny {
  @include font-size(12px);
}
.loading-text--small {
  @include font-size(14px);
}
.loading-text--large {
  @include font-size(20px);
}
.loading-text--huge {
  @include font-size(36px);
}
