/* -Start page
-----------------------------------------------------------------------------*/
.block-charity {
  padding-top: 350px;

  @include media-max(900px) {
    background-position: 30% 50%;
  }
  @include media-max(450px) {
    background-position: 35% 50%;
  }
}
.charity-logos {
  img {
    margin: 10px 20px;
  }
}
.charity-barnsamariten {
  max-width: 100px;
}
.charity-lovehope {
  max-width: 200px;
}
.charity-text {
  padding: 30px 0;
  background: #fff;
  background: rgba(255, 255, 255, 0.8);
  text-align: center;

  p:last-child {
    margin-bottom: 0;
  }
}
