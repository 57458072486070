/* -Global site notice
-----------------------------------------------------------------------------*/
.notice {
  padding: 15px 0;
  background: $color-gold;
  color: #fff;
  @include font-size(20px);
  font-weight: $font-weight-light;
  text-align: center;

  p {
    margin: 0;
  }
}
