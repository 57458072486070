/* -Text blurb, possible with an image and/or a read more link
-----------------------------------------------------------------------------*/
.blurb {
  @include clearfix;
}

.blurb--no-image {
  text-align: center;

  ul {
    display: inline-block;
    text-align: left;
  }
}
.blurb-image {
  float: left;
  width: 30%;
  margin-right: 20px;
}
.blurb-text {
  overflow: hidden;
}
