/* -Quote block
---------------------------------------------------------------------------- */
.block-quote {
  @include section-margin;

  blockquote {
    margin-left: 0;
    margin-right: 0;
    margin-bottom: 0;
  }
}
