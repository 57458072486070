/* -Contact forms
-----------------------------------------------------------------------------*/
.contact-form {
  position: relative;
}

.contact-form-fields,
.contact-form-footer {
  transition: opacity 0.25s ease;

  .contact-form--hidden & {
    opacity: 0;
  }
}

.contact-form-footer {
  text-align: center;

  p {
    margin: 5px 0;
  }
  button[type='submit'] {
    min-width: 8em;
  }
}

/*---------- Fields ----------*/
/* Receipt inherits fields layout */
.contact-form-fields,
.form-receipt-fields {
  @include grid-wrap;
  @include grid-wrap-size(20px);
}
.contact-form-footer {
  @include grid-item(12);
  @include grid-item-gutter-size(20px);
}
.form-field {
  @include grid-item(6);
  @include grid-item-gutter-size(20px);

  padding-bottom: 5px;

  input:not([type='radio']):not([type='checkbox']),
  select,
  textarea {
    width: 100%;
  }
  textarea {
    height: 7em;
  }
}
.form-field--radio {
  padding-bottom: 10px;
}

/* Specific field sizes */
.form-field--postal_code {
  width: 15%;
}
.form-field--price_min,
.form-field--price_max,
.form-field--rooms_min,
.form-field--rooms_max,
.form-field--plot_min,
.form-field--plot_max,
.form-field--area_min,
.form-field--area_max {
  width: 25%;
}

.form-field--city {
  width: 35%;
}
.form-field--property_designation,
.form-field--message,
.form-field--accept_terms {
  width: 100%;
}

@include media-max(em(900px)) {
  .form-field--postal_code {
    width: 20%;
  }
  .form-field--city {
    width: 30%;
  }
  .form-field--area {
    width: 100%;
  }
}
@include media-max(em(700px)) {
  .form-field--address {
    width: 100%;
  }
  .form-field--postal_code {
    width: 35%;
  }
  .form-field--city {
    width: 65%;
  }
}
@include media-max(em(450px)) {
  .form-field {
    width: 100%;
  }
}

/*---------- Receipt ----------*/
.form-receipt {
  animation: 0.25s ease 0.25s fade-in;

  .overlay-content {
    position: relative;
    padding-bottom: 40px; /* Match pseudo bottom */

    &:after {
      content: '';
      display: block;
      position: absolute;
      bottom: 40px; /* Match padding */
      left: 0;
      width: 100%;
      height: 4em;
      background: linear-gradient(
        to bottom,
        rgba(255, 255, 255, 0),
        rgba(255, 255, 255, 1)
      );
    }
  }
  .message-box {
    margin-top: 0;
  }
}
.form-receipt-fields {
  padding-bottom: 2em;
}
.form-receipt-field {
  margin-bottom: 1em;
  padding-bottom: 0;
  @include font-size($form-control-font-size);

  strong {
    display: block;
    @include font-size(1em);
  }
}
.form-receipt-field--message {
  white-space: pre-line;
}
.form-receipt-close {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  margin: 0;
  text-align: center;
}

.form-receipt--bordered {
  .overlay-content {
    padding: 30px 30px 70px; /* Add to default bottom padding */
    border: 1px solid #ddd;

    &:after {
      bottom: 70px;
    }
  }
  .form-receipt-close {
    bottom: 30px;
  }
}

.form-receipt--limited-height {
  .overlay-content,
  .form-receipt-fields {
    height: 100%;
  }
  .form-receipt-fields {
    overflow: auto;
  }
  // .flexbox & {
  display: flex;
  align-items: flex-start;

  .overlay-content {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  .overlay-content,
  .form-receipt-fields {
    height: auto;
    max-height: 100%;
    flex: 1 1 auto;
  }
  // }
}
