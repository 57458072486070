/* -Site footer
-----------------------------------------------------------------------------*/
$site-footer-padding: 40px;

.site-footer {
  clear: both;
  text-align: center;
}

.site-footer-about {
  padding: $site-footer-padding 20px;
  background: $color-gray-light;
}
.site-footer-about-links {
  margin: 0 auto;

  li {
    padding: 10px 15px;
    white-space: nowrap;
  }
  .icon,
  .text {
    vertical-align: middle;
  }
  .icon {
    position: relative;
    margin-right: 5px;
    top: 1px;

    svg {
      fill: $color-body-foreground;
    }
  }
  a {
    text-decoration: none;

    .text {
      text-decoration: underline;
    }
    &:hover,
    &:focus {
      .text {
        text-decoration: none;
      }
    }
  }
}
.site-footer-about-more {
  margin: 1.5em 0 0;

  a {
    display: inline-block;
    margin: 0.25em 0.5em;
    padding: 0.25em;
  }
}

.site-footer-certified {
  padding: 10px;
  background: $color-brand-blue;
  color: #fff;

  img {
    max-width: 100px;
  }
  @include media-max(em(500px)) {
    padding: 7px;

    img {
      max-width: 70px;
    }
  }
}

@include media-max(em(650px)) {
  .site-footer-about-links {
    margin-bottom: 20px;

    li {
      display: block;
      padding: 0 0 5px;
    }
  }
}
