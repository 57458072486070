/* -Loading spinner component
-----------------------------------------------------------------------------*/
@keyframes spinner {
  to {
    transform: rotate(360deg);
  }
}

.spinner {
  display: inline-block;
  position: relative;
  overflow: hidden;
  width: 1em;
  height: 1em;
  border: 2px solid rgba(0, 0, 0, 0.5);
  border-left-color: transparent;
  border-right-color: transparent;
  border-radius: 8px;
  vertical-align: middle;
  transform: rotate(0deg);
  animation: spinner 0.5s linear 0s infinite;
}
.spinner--light {
  border-top-color: rgba(255, 255, 255, 0.5);
  border-bottom-color: rgba(255, 255, 255, 0.5);
}
.spinner--white {
  border-top-color: #fff;
  border-bottom-color: #fff;
}
.spinner--black {
  border-top-color: $color-body-foreground;
  border-bottom-color: $color-body-foreground;
}

.spinner--rainbow,
.spinner--rainbow:before {
  border: 0;
  box-shadow: inset 3px 0 0 rgba(250, 5, 40, 0.5),
    inset 0 3px 0 rgba(252, 150, 0, 0.5), inset -3px 0 0 rgba(10, 255, 30, 0.5),
    inset 0 -3px 0 rgba(0, 150, 255, 0.5);
}
.spinner--rainbow:before {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  transform: rotate(30deg);
}

.spinner-text {
  margin-right: 0.5em;
  font-style: italic;
}
