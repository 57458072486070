/* -Single estate view
-----------------------------------------------------------------------------*/

/* TODO: separate general estate components and single view layout */

/*---------- Header/intro ----------*/
.estate-header {
  padding-top: 40px;
  padding-bottom: 40px;
  text-align: center;

  .h-alt {
    margin: 0;
  }
}

/*---------- Nav ----------*/
.estate-nav {
  position: sticky;
  top: 0;
  z-index: $z-fixed-nav;

  padding: 5px 10px;
  border-top: 1px solid $color-white-separator;
  border-bottom: 1px solid $color-white-separator;
  background-color: rgba(255, 255, 255, 0.95);
  text-align: center;
  z-index: $z-fixed-nav;

  .backdropfilter & {
    background-color: rgba(255, 255, 255, 0.75);
    backdrop-filter: saturate(160%) blur(20px);
  }
  .modal-trigger {
    height: auto;
    padding: 1px 8px;
    line-height: 1.5;
  }
  li {
    margin: 0 5px;
  }
  a {
    padding: 10px 5px;
  }
  @include media-max(em(500px)) {
    .modal-wrap {
      display: none !important;
      visibility: hidden !important;
    }
  }
  @include media-max(em(400px)) {
    li {
      margin: 0 2px;
    }
    a {
      @include font-size(14px);
    }
  }
}

/*---------- General ----------*/
// Lightbox items is rendered in a not visual div to keep selected order from
// Vitec admin, otherwise the order gets broken when displayed in two columns.
.lightbox-items-container {
  display: none;
}
.estate-main-intro {
  position: relative;
  overflow: hidden;

  img {
    width: 100%;
  }
}

.estate-main-intro--lightbox-trigger {
  position: absolute;
  right: 10px;
  bottom: 10px;
  z-index: 1;

  @include media-min(rem(600px)) {
    right: 20px;
    bottom: 20px;
  }

  @include media-min(rem(800px)) {
    right: 40px;
    bottom: 40px;
  }
}

.estate-content-wrap {
  border-left: 1px solid $color-white-separator;
}

/*---------- Overview ----------*/

/* General overview blocks */
.estate-overview {
  display: flex;
  @include grid-wrap;
  @include grid-wrap-size(60px);
}
.estate-overview-col {
  @include grid-item-gutter-size(60px);
  position: relative;
  width: 35%;
  padding-top: 60px;
  padding-left: $grid-gutter;
  z-index: 2;

  @include when-tight-grid {
    padding-left: $grid-gutter-tight;
  }
}
.estate-overview-info-col {
  width: 30%;
}
.estate-overview-block {
  @include font-size(18px);
}
.estate-overview-title {
  margin-bottom: 10px;
  @include font-size(20px);
  font-weight: $font-weight-bold;
}

/* Short facts */
.estate-short-facts {
  ul {
    list-style: none;
    margin: 0 0 1em;
  }
  li {
    display: inline-block;
    width: 50%;
    padding-right: 20px;
    vertical-align: top;
    margin-bottom: 20px;
  }
  strong {
    display: block;
    color: $color-lightest-foreground-on-white;
    @include font-size(15px);
    text-transform: uppercase;
  }
  p:last-child {
    margin-bottom: 0;
  }
}

/* Price and viewings */
.estate-price,
.estate-viewing,
.estate-type {
  margin-bottom: 15px;
  padding: 20px;

  .estate-overview-title,
  p {
    margin: 0;
  }
}
.estate-price {
  background: $color-gray-light;
}
.estate-type {
  background: $color-cream;
}
.estate-price-starting-amount,
.estate-viewing-dates {
  @include font-size(36px);
  font-weight: $font-weight-light;
}
.estate-price-starting-currency {
  @include font-size(21px);
  font-weight: $font-weight-light;
}
.estate-price-latest {
  @include font-size(14px);
}
.estate-price-latest-amount {
  @include font-size(18px);
  font-weight: $font-weight-light;
}
.estate-viewing {
  background: $color-blue-light;
}
.estate-viewing-contact,
.estate-price-contact,
.estate-type-info {
  @include font-size(14px);
}

/* Share */
.estate-share {
  margin-top: 40px;
  text-align: center;

  ul {
    list-style: none;
    margin: 15px 0 0;
  }
  li {
    margin-bottom: 10px;
  }
  .icon {
    margin-right: 5px;
  }
  svg {
    fill: $color-brand-blue;
  }
  a {
    text-decoration: none;

    .text {
      position: relative;
      top: -1px;
      text-decoration: underline;
    }
    &:hover,
    &:focus {
      .text {
        text-decoration: none;
      }
      svg {
        fill: currentColor;
      }
    }
  }
}

/* Agent */
.estate-agents {
  .estate-info-title {
    margin-bottom: 5px;
  }
  p {
    margin: 5px 0;
  }
}
.estate-agent {
  &:not(:last-child) {
    margin-bottom: 40px;
  }
}
.estate-agent-image {
  float: left;
  width: 120px;
  height: 120px;
  margin-right: 20px;
  border-radius: 60px;
}
.estate-agent-text {
  overflow: hidden;
}
.estate-agent-name {
  margin: 0;
}

@include media-max(em(1200px)) {
  .estate-overview-facts-col,
  .estate-overview-info-col {
    @include grid-item-size(6);
  }
  .estate-overview-contact-col {
    @include grid-item-size(12);
  }
  .estate-agents {
    @include grid-wrap;
    text-align: center;
  }
  .estate-agent {
    @include grid-item(12);
    max-width: em(400px);
    text-align: left;
  }
}
@include media-max(em(650px)) {
  .estate-overview {
    @include grid-wrap-reset;
  }
  .estate-overview-col {
    @include grid-item-reset;
    padding-top: 30px;
  }
  .estate-short-facts {
    ul {
      margin: 0;
    }
  }
}
@include media-max(em(400px)) {
  .estate-overview-block {
    @include font-size(16px);
  }
  .estate-overview-title {
    @include font-size(18px);
  }
  .estate-price-starting-amount,
  .estate-viewing-dates {
    @include font-size(24px);
  }
  .estate-agent-image {
    width: 90px;
    height: 90px;
  }
  .estate-agent-name {
    @include font-size(24px);
  }
}

/*---------- Content sections ----------*/
.estate-section {
  margin-top: 40px;
  padding-top: 80px;
  border-top: 1px solid $color-white-separator;
}
.estate-section-heading {
  text-align: center;
}
.estate-section-text {
  padding-bottom: 40px;
  text-align: center;
}
.estate-section-text--left {
  text-align: left;
}
.estate-map {
  margin-top: 80px;
}
@include media-max(em(800px)) {
  .estate-section {
    margin-top: 30px;
    padding-top: 60px;
  }
  .estate-section-text {
    padding-bottom: 30px;
  }
}
@include media-max(em(500px)) {
  .estate-section {
    margin-top: 20px;
    padding-top: 40px;
  }
  .estate-section-text {
    padding-bottom: 20px;
  }
  .estate-map {
    margin-top: 60px;
  }
}

/*---------- Description images -----------*/
$description-images-gutter: 30px;
$description-images-gutter-small: $description-images-gutter * 0.5;

.estate-description-images--full {
  max-width: 850px;
  margin-left: auto;
  margin-right: auto;
}
.estate-description-images--secondary {
  margin-top: $description-images-gutter-small;
}

.estate-description-image {
  a:focus {
    display: block;
    width: 100%;
    height: 100%;
    outline: 2px solid $color-body-foreground;
  }
}

.section-images--left,
.section-images--right {
  margin-top: $description-images-gutter-small;
}

.more-images {
  visibility: hidden;
  opacity: 0;
  height: 0;
  transition: opacity 1s ease;
  transition-delay: 0.5s;
  transition-property: opacity;

  .estate-description-image {
    margin-top: $description-images-gutter-small;
    transform: scale(0.5);
    transition: transform 0.5s ease;
    transition-delay: 0.5s;
  }
  // Keep hidden child elements from not overflow and cause whitespace.
  * {
    height: inherit;
  }
}

.estate-description-image {
  &:not(:first-child) {
    margin-top: $description-images-gutter-small;
  }
  img {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
}
.estate-description-image-text {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  margin: 0;
  padding: 30px 15px 10px;
  background: linear-gradient(rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.65) 100%);
  color: #fff;
  z-index: 2;
}
.show-more-btn {
  border: none;
  color: $color-body-foreground;
  height: 80px;
  width: 100%;
  margin-top: $description-images-gutter-small;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: $color-gray-light;
  font-size: rem(26px);

  &:hover,
  &:focus {
    background-color: darken($color-gray-light, 5%);
  }
}

@include media-min(rem(850px)) {
  .estate-description-images--primary,
  .estate-description-images--secondary {
    display: inline-block;
    vertical-align: top;
  }

  .estate-description-images--primary {
    width: 60%;
    padding-right: 15px;
  }
  .estate-description-images--secondary {
    margin-top: 0;
    width: 39%;
    padding-left: 15px;
  }
  .section-images--left,
  .section-images--right {
    margin-top: 0;
  }

  .section-images--grid {
    display: flex;
    height: 800px;
    visibility: visible;
    opacity: 1;
    transition: opacity 0.4s ease, visibility 0s ease, height 0s ease;
    transition-delay: 0s, 0.5s, 0.5s;
  }
  .section-images--left {
    flex-basis: 60%;
    padding-right: 15px;
    min-height: 0;
    display: flex;
    flex-direction: column;

    .estate-description-image {
      flex: 1;
      min-height: 0;

      &:not(:first-child) {
        margin-top: $description-images-gutter;
      }
      &:nth-child(2) {
        margin-top: $description-images-gutter;
      }

      .lazy-image {
        padding-bottom: 0 !important;
        height: 100% !important;
      }
    }
  }
  .section-images--right {
    padding-left: 15px;
    flex-grow: 1;
    display: flex;
    flex-direction: column;

    .estate-description-image {
      min-height: 0;
      flex: 2;

      .lazy-image {
        padding-bottom: 0 !important;
        height: 100% !important;
      }
    }
  }
  .more-images {
    .estate-description-image {
      margin-top: $description-images-gutter;
    }
  }
  .show-more-btn {
    margin-top: $description-images-gutter;
    height: 120px;
  }
}

/*---------- Floor plans ----------*/
.estate-floor-plans {
  text-align: center;
}
.floor-plan-image {
  display: inline-block;
  width: 100%;
  max-width: 600px;
  margin: 10px;
}

/*---------- Documents ----------*/
.estate-documents {
  text-align: center;

  ul {
    list-style: none;
    margin: 30px 0 0;
  }
  li {
    display: inline-block;
    margin: 10px 20px;
  }
  a {
    display: block;

    .text {
      display: block;
    }
    &:hover,
    &:focus {
      .icon svg {
        fill: $color-action;
      }
    }
  }
  .icon {
    width: $icon-document-width * 3;
    height: $icon-document-height * 3;
    margin: 0 0 5px !important;

    svg {
      fill: $color-brand-blue-soft;
    }
  }
}

/*---------- Commercial compilation areas ----------*/
.compilation-areas {
  width: 100%;
}

/*---------- Facts ----------*/
.estate-facts-wrap {
  dl,
  dt,
  dd {
    margin: 0;
  }
  dl {
    @include grid-wrap-classic;
  }
  dt,
  dd {
    margin-bottom: 10px;
  }
  dt {
    @include grid-item-classic(4);
    padding-right: 20px;
    word-wrap: break-word;
  }
  dd {
    @include grid-item-classic(8);
    white-space: pre-line;
  }
  dl dl {
    @include grid-wrap-classic-reset;

    dt,
    dd {
      @include grid-item-classic-reset;
    }
    dt {
      margin: 0;
      padding: 0;
    }
  }
}
.estate-facts-title {
  text-align: center;
}
.estate-facts-group-title {
  margin: 50px 0 25px;
  color: inherit;
}
@include media-max(em(500px)) {
  .estate-facts-wrap {
    dl {
      @include grid-wrap-classic-reset;

      dl {
        margin-top: 10px;
        margin-left: 20px;
      }
    }
    dt,
    dd {
      @include grid-item-classic-reset;
      margin: 0;
      padding: 0;
    }
    dd {
      margin-bottom: 1em;
    }
  }
}

/*---------- Prospect form for discrete objects ----------*/

.estate-prospect-form {
  @include section-margin-top;
  @include section-padding-top;
  // Decrease padding bottom to make up for extra space from hidden form error.
  @include section-padding-bottom(0.75);

  .text {
    text-align: center;
  }
}

/*---------- Other estates ----------*/
.other-estates-more {
  margin-top: -40px;
  text-align: center;
}
