/* -Form elements
-----------------------------------------------------------------------------*/
input[type='tel'],
input[type='url'],
input[type='text'],
input[type='email'],
input[type='search'],
input[type='password'],
textarea,
.Select-control {
  padding: 0 $form-field-padding;
  border: 1px solid $color-form-field-border;
  border-radius: $form-control-radius;
  background-color: $color-form-field;
  color: $color-body-foreground;
  @include font-size($form-control-font-size);
  line-height: 1.5;
  transition: border-color 0.15s ease, background-color 0.15s ease;

  &:hover {
    border-color: $color-form-field-border-hover;
  }
  &:focus {
    border-color: $color-form-field-border-focus;
    background-color: #fff;
    outline: 0;
  }
  &[disabled] {
    border-color: #eee;
    color: #666;
  }
}
input[type='tel'],
input[type='url'],
input[type='text'],
input[type='email'],
input[type='search'],
input[type='password'] {
  height: $form-control-height;
}
select {
  padding: 5px;
}
textarea {
  min-height: $form-control-height;
}

form {
  input,
  select,
  textarea {
    display: block;
  }
  input[type='submit'],
  input[type='button'],
  input[type='reset'],
  input[type='radio'],
  input[type='checkbox'] {
    display: inline-block;
  }
}

input[type='radio'],
input[type='checkbox'] {
  vertical-align: middle;

  & + label,
  label + & {
    padding-left: 3px;
  }
}

label {
  display: inline-block;
  vertical-align: middle;
}

/* IE 'clear field' button doesn't seem to fire any logical events and is thus
impossible to work with for controlled input components. Just remove it. */
::-ms-clear {
  display: none;
}

.grouped-inputs {
  display: flex;

  input,
  textarea,
  button,
  .btn {
    &:first-child {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }
    &:last-child {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }
  }
}

.radio-field,
.checkbox-field {
  input,
  label > span {
    display: inline-block;
    vertical-align: middle;
  }
  label > span {
    padding-left: 5px;
  }
}
.radio-field {
  display: inline-block;
  padding-top: 5px;
  margin-right: 20px;
}
.field-group--checkbox {
  @include grid-wrap;
}
.checkbox-field {
  @include grid-item(4);
  padding-top: 5px;

  @include media-max(em(450px)) {
    @include grid-item-size(6);
  }
}

.field-req {
  border: 0 !important;
  text-decoration: none !important;
  color: $color-error;
}

.field-hint {
  display: block;
  color: $color-lightest-foreground-on-white;
  @include font-size(14px);
}

.form-error-small {
  display: block;
  margin-top: 5px;
}

.errorlist {
  .message-box & {
    // There is an icon on the left, so add an approximately matching amount of
    // space to the right so that the text is more centered.
    margin: 0 20px 0 0;
  }
  li:first-child:last-child {
    list-style: none;
    margin: 0;
  }
}
