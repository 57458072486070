/* -Block with background image and a text box
-----------------------------------------------------------------------------*/

.block-background-image-text-box {
  text-align: right;
}
.block-background-image-text-box-content {
  display: inline-block;
  width: 55%;
  padding: 30px;
  background: #fff;
  background: rgba(255, 255, 255, 0.8);
  text-align: left;

  @include media-max(em(750px)) {
    display: block;
    width: auto;
  }
}
