/* -Keyframe animations
-----------------------------------------------------------------------------*/
@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes scale-up {
  from {
    transform: scale(0.9);
  }
  to {
    transform: scale(1);
  }
}

@keyframes slide-up {
  from {
    transform: translateY(30px);
  }
  to {
    transform: translateY(0);
  }
}
