/* -Section block - a background with other blocks inside
---------------------------------------------------------------------------- */
.section-block--bg {
  @include section-padding;

  > *:first-child {
    margin-top: 0 !important;
  }
  > *:last-child {
    margin-bottom: 0 !important;
  }
}

.section-block--bg-gray {
  background-color: $color-gray-light;
}
.section-block--bg-beige {
  background-color: $color-cream;
}
