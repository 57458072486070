/* -Embedded map
-----------------------------------------------------------------------------*/
.map {
  position: relative;
  height: 500px;
  background: $color-gray-light;

  .map-loading {
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    width: 10em;
    height: 2em;
    line-height: 2em;
    margin-top: -1em;
    margin-left: -5em;
    text-align: center;
    color: #999;
    z-index: 1;
  }
  iframe {
    display: block;
    position: relative;
    z-index: 2;
  }
}
.map-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 5;
}
.map--active {
  .map-overlay {
    width: 0;
    height: 0;
  }
}
