/* -Main header
-----------------------------------------------------------------------------*/
body {
  border-top: 20px solid $color-site-header-border;
}
.site-header {
  position: relative;
  display: table;
  width: 100%;
}
.site-header-notice {
  display: table-caption;
}
.site-header-logo,
.site-header-nav {
  display: table-cell;
  vertical-align: middle;
  padding-top: 30px;
  padding-bottom: 30px;
  z-index: 2;
}
.site-header-logo {
  padding-left: 50px;
  padding-right: 10px;

  img {
    max-width: 260px;
  }
}
.site-header-nav {
  padding-left: 10px;
  padding-right: 50px;
  text-align: right;
}

@include media-max(em(850px)) {
  .site-header-logo,
  .site-header-nav {
    padding-top: 20px;
    padding-bottom: 20px;
  }
  .site-header-logo {
    padding-left: 25px;
  }
  .site-header-nav {
    padding-right: 25px;
  }
}
@include media-max($breakpoint-header-stacked) {
  body {
    border-top: 0;
  }
  .site-header-logo,
  .site-header-nav {
    text-align: center;
    padding-left: 20px;
    padding-right: 20px;
  }
  .site-header-notice {
    display: table-row;
  }
  .site-header-nav {
    display: table-caption;
    padding: 0;
  }
  .site-header-logo {
    display: table-footer-group;

    .site-logo {
      margin: 15px auto;
    }
  }
}

@include media-min(rem($breakpoint-header-stacked)) {
  .site-header {
    .start-page-has-intro-video &,
    &.site-header--alternate {
      position: absolute;
      left: 0;
      top: 0;
      z-index: 2;

      &::after {
        content: '';
        position: absolute;
        left: 0;
        top: 20px;
        width: 100%;
        height: 90%;
        z-index: -1;
        background: linear-gradient(
          to bottom,
          rgba(255, 255, 255, 0.8),
          70%,
          rgba(255, 255, 255, 0)
        );
      }
    }
  }

  .site-header-logo,
  .site-header-nav {
    .start-page-has-intro-video &,
    .site-header--alternate & {
      padding-top: 40px;
      padding-bottom: 40px;
    }
  }
}
