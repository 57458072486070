/* -Estate status label
-----------------------------------------------------------------------------*/
$estate-status-label-height: 40px;

.estate-status-label {
  display: inline-block;
  position: absolute;
  top: 25px;
  left: 0;
  min-width: 6em;
  height: $estate-status-label-height;
  line-height: $estate-status-label-height;
  padding: 0 20px;
  color: #fff;
  @include font-size(18px);
  text-transform: uppercase;
  text-align: center;
  z-index: 5;

  &:after {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 100%;
    border: ($estate-status-label-height * 0.5) solid;
    border-left-width: 0;
    border-right-color: transparent;
  }
}
.estate-status-label--sold {
  background: $color-blue;

  &:after {
    border-top-color: $color-blue;
    border-bottom-color: $color-blue;
  }
}
.estate-status-label--bidding {
  background: $color-brown;

  &:after {
    border-top-color: $color-brown;
    border-bottom-color: $color-brown;
  }
}
