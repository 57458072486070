.contact-person {
  display: inline-block;
  width: 33%;
  margin-bottom: 20px;
  padding-left: 10px;
  padding-right: 10px;
  text-align: center;

  p {
    margin: 4px 0;
  }
}
