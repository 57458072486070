/* -Global notification messages
-----------------------------------------------------------------------------*/
.notifications {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: $z-notifications;
}
.notification {
  padding: 10px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  background: $color-body-foreground;
  color: #fff;
  text-align: center;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.5);
}

.notification-error {
  background: $color-notification-error;
  color: $color-notification-error-foreground;
}
.notification-success {
  background: $color-notification-success;
  color: $color-notification-success-foreground;
}
.notification-warning {
  background: $color-notification-warning;
  color: $color-notification-warning-foreground;
  text-shadow: none;
}
.notification-info {
  background: $color-notification-info;
  color: $color-notification-info-foreground;
}

.notification-text {
  vertical-align: middle;
}
.notification-close {
  width: 25px;
  height: 25px;
  line-height: 25px;
  margin-left: 10px;
  padding: 0;
  border: 0;
  vertical-align: middle;
  color: inherit;
  background: transparent;
  font-size: 16px;
  font-weight: $font-weight-bold;

  .icon {
    width: 10px;
    height: 10px;
  }
  .notification-error & {
    background: desaturate(darken($color-notification-error, 10%), 10%);

    &:hover,
    &:focus {
      background: desaturate(darken($color-notification-error, 15%), 20%);
    }
  }
  .notification-success & {
    background: desaturate(darken($color-notification-success, 5%), 10%);

    &:hover,
    &:focus {
      background: desaturate(darken($color-notification-success, 10%), 20%);
    }
  }
  .notification-warning & {
    background: desaturate(darken($color-notification-warning, 15%), 10%);

    &:hover,
    &:focus {
      background: desaturate(darken($color-notification-warning, 20%), 20%);
    }
  }
  .notification-info & {
    background: desaturate(darken($color-notification-info, 10%), 10%);

    &:hover,
    &:focus {
      background: desaturate(darken($color-notification-info, 15%), 20%);
    }
  }
}
