/* -Toggle dialog component
-----------------------------------------------------------------------------*/
@use "sass:math";

$toggle-dialog-margin: 10px;
$toggle-dialog-padding: 10px;

// Get the diagonal length that fills the dialog box padding, since the arrow
// is a rotated square. Any larger and the white overlay of the arrow will
// overlap the dialog contents.
$toggle-dialog-arrow-size: floor(
    sqrt(
      pow(math.div($toggle-dialog-padding, 1px), 2) +
        pow(math.div($toggle-dialog-padding, 1px), 2)
    )
  ) * 1px;

.toggle-dialog-wrap {
  position: relative;
}
/* .toggle-dialog-trigger {
  position: relative;

  .csstransforms .toggle-dialog-wrap-open > & {
    &:before,
    &:after {
      content: '';
      display: block;
      position: absolute;
      left: 50%;
      width: $toggle-dialog-arrow-size;
      height: $toggle-dialog-arrow-size;
      margin-left: -($toggle-dialog-arrow-size / 2);
      background: #fff;
      @include transform(rotate(45deg));
    }
    &:before {
      box-shadow: 0 0 2px rgba(0,0,0,0.25);
    }
    &:after {
      z-index: 11;
    }
  }
  .csstransforms .toggle-dialog-wrap-open.toggle-dialog-bottom > & {
    &:before,
    &:after {
      top: 100%;
      margin-top: $toggle-dialog-margin - floor($toggle-dialog-arrow-size / 2);
    }
  }
  .csstransforms .toggle-dialog-wrap-open.toggle-dialog-top > & {
    &:before,
    &:after {
      bottom: 100%;
      margin-bottom: $toggle-dialog-margin - floor($toggle-dialog-arrow-size / 2);
    }
  }
} */
.toggle-dialog {
  @include shadow-2;
  position: absolute;
  top: 100%;
  margin-top: $toggle-dialog-margin;
  padding: $toggle-dialog-padding;
  overflow: hidden;
  background: #fff;
  z-index: 10;

  .no-boxshadow & {
    border: 1px solid $color-gray-light;
  }
  .toggle-dialog-top & {
    top: auto;
    bottom: 100%;
    margin-top: 0;
    margin-bottom: $toggle-dialog-margin;
  }
  .toggle-dialog-inline & {
    position: relative;
    top: auto;
    bottom: auto;
    left: auto;
  }
}
.toggle-dialog-inner {
  background: #fff;
}

.toggle-dialog-text {
  display: block;

  > span {
    vertical-align: middle;
  }
}
