/* -Block with image and content blocked side by side
-----------------------------------------------------------------------------*/
$image-and-content-columns-breakpoint: rem(700px);

.image-content-block {
  @include section-margin;
}

.image-content-block__content-first {
  @include section-margin-bottom(0.5);
}

.image-content-block__inner--reversed {
  display: flex;
  flex-direction: column;
  flex-direction: column-reverse;
}
.image-content-block__content {
  .image-content-block__inner--reversed & {
    margin-bottom: $text-spacing;
  }

  > *:last-child {
    margin-bottom: 0;
  }
}
.image-content-block__image {
  margin-bottom: $text-spacing;

  .image-content-block__inner--reversed & {
    margin-bottom: 0;
  }
}
.image-content-block__image--stretch {
  img {
    max-height: 250px;
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
}

// Two columns
@include media-min($image-and-content-columns-breakpoint) {
  .image-content-block__inner {
    @include grid-wrap;

    &.image-content-block__inner--reversed {
      flex-direction: row-reverse;
    }
  }
  .image-content-block__image,
  .image-content-block__content {
    @include grid-item(6);

    margin-bottom: 0;
  }
  .image-content-block__image img {
    max-height: none;
  }
}
